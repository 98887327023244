@font-face {
  font-family: 'Poppins-Regular';
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
}

.oswaldBold {
  font-family: 'oswald', sans-serif;
  font-weight: 700;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  margin: 0;
}

h1 {
  font-size: 4rem;
  font-family: 'oswald', sans-serif;
  font-style: normal;
  line-height: 1.2;
  color: #87c7f7;
}

h1 .light-blue-color {
  color: #b9e0f7;
}

h1 .gold {
  color: #d9a700;
}

h2,
.llr-headline h1,
h2 {
  font-size: 2.8rem;
  font-family: 'oswald', sans-serif;
  font-style: normal;
  line-height: 1.2;
  color: #87c7f7;
}

h2 .light-blue-color,
.llr-headline h1 .light-blue-color,
h2 .light-blue-color {
  color: #b9e0f7;
}

h2 .gold,
.llr-headline h1 .gold,
h2 .gold {
  color: #d9a700;
}

h3 {
  font-family: 'Poppins-Semibold';
  font-size: 2rem;
}

h5 {
  font-family: 'Poppins-Semibold';
}

p {
  font-size: 1.3rem;
  font-family: 'Poppins-Regular';
  color: #b9e0f7;
  line-height: 1.4;
}

p a {
  color: inherit;
}

h4,
h6 {
  color: #87c7f7;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: 'Poppins-Semibold';
}

footer h2 {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: 'Poppins-Bold';
  text-transform: uppercase;
  margin-bottom: 1.2rem;
}

.poppins-container p {
  font-family: 'Poppins-Semibold';
}

.poppins-semibold {
  font-family: 'Poppins-Semibold';
  text-transform: uppercase;
}

.question h2 {
  font-size: 1.4rem;
  font-family: 'Poppins-Semibold';
  text-transform: capitalize;
}

@media only screen and (min-width: 900px) {
  .question h2 {
    font-size: 2.6rem;
  }
}

.strain-info-slider {
  color: #b9e0f7;
}

.strain-info-slider h5:first-child {
  margin: 0 auto 15px;
}

.strain-info-slider h5 {
  font-size: 2.2rem;
  margin: 50px auto 15px;
  line-height: 1.2;
}

.strain-info-slider p {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.strain-info-slider .title {
  font-size: 1.4rem;
}

.answer p {
  font-size: 1.2rem;
}

@media only screen and (min-width: 900px) {
  .answer p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 900px) {
  h1 {
    font-size: 8rem;
    line-height: 1;
  }
  h2 {
    font-size: 5.5rem;
  }
  h4,
  h6 {
    font-size: 2.6rem;
  }
  footer h2,
  footer a,
  footer p {
    font-size: 1.4rem;
  }
  p {
    font-size: 1.6rem;
  }
  .llr-headline h1,
  .llr-headline h2 {
    font-size: 6rem;
  }
}

@media screen and (min-width: 1400px) {
  h1 {
    font-size: 12rem;
  }
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  font-size: 10px;
  background: #02060a;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  height: 100%;
  width: 100%;
}

.MuiSelect-outlined.MuiSelect-outlined {
  color: #87c7f7 !important;
  font-size: 1.8rem !important;
  text-align: center;
  font-family: 'Poppins-Semibold';
  text-transform: uppercase;
}

.slice-single {
  background: #02060a;
}

.uppercase {
  text-transform: uppercase;
}

.contain-img {
  margin: 0;
  padding: 0;
  display: block;
}

.contain-img img {
  display: block;
  max-width: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0;
  padding: 0;
}

.fullscreen-background {
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.fullscreen-background img {
  display: block;
  max-width: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}

.cover-img {
  margin: 0;
  padding: 0;
  height: 100%;
}

.cover-img span {
  width: 100%;
  height: 100%;
}

.cover-img img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 0;
  padding: 0;
}

.display-block {
  display: block !important;
}

.dark-blue {
  color: #001f3b;
}

.light-blue {
  color: #87c7f7;
}

.light-blue a {
  color: inherit;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.resp-mobile-bottom {
  margin-bottom: 20px;
}

.overflow-hidden {
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.no-underline {
  text-decoration: none;
}

.text-center,
.center {
  text-align: center;
}

.text-left,
.left {
  text-align: left;
}

.text-right,
.right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.mx-auto {
  margin: auto;
}

.relative {
  position: relative;
}

.padding {
  padding: 80px 35px;
}

@media only screen and (min-width: 900px) {
  .padding {
    padding: 160px 50px;
  }
}

.padding-less {
  padding: 80px 20px;
}

@media only screen and (min-width: 900px) {
  .padding-less {
    padding: 160px 50px;
  }
}

.padding-tb {
  padding: 80px 0;
}

@media only screen and (min-width: 900px) {
  .padding-tb {
    padding: 160px 0;
  }
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.height-100 {
  height: 100%;
}

.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.max-width {
  max-width: 1400px;
  margin: auto;
}

.scroller [data-index='0'] {
  margin-left: 20px;
}

.lighter-blue {
  color: #b9e0f7;
}

figure {
  margin: 0;
  padding: 0;
}

.resp-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(auto, 220px))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(auto, 220px));
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.resp-grid.gap {
  gap: 10px 0px;
}

.height-auto {
  height: auto;
}

.loading-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #001f3b;
  z-index: 1000000000;
}

@media only screen and (min-width: 900px) {
  .resp-mobile-bottom {
    margin-bottom: 57px;
  }
  .scroller.results-scroller {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

nav {
  min-height: 56px;
  position: fixed;
  width: 100%;
  margin: auto;
  top: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 5;
}

nav .mobile-bar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 60px;
  padding: 0 0 0 17px;
  background: #02060a;
}

nav .flower-icon {
  width: 45px;
  height: 45px;
  margin: 0;
  padding: 0;
  margin-top: -5px;
}

nav ul {
  display: none;
  list-style: none;
  padding: 38px 0;
}

nav ul li {
  height: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 300ms ease-in;
  transition: 300ms ease-in;
}

nav ul li:hover, nav ul li:active, nav ul li.active {
  background-color: rgba(101, 157, 200, 0.5);
}

nav ul a {
  text-transform: uppercase;
  display: block;
  font-size: 1.4rem;
  font-family: Poppins-Semibold;
  color: #87c7f7;
  text-decoration: none;
}

nav ul.openNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(2, 6, 10, 0.9);
  color: #87c7f7;
  margin: 0;
}

nav #nav-logo img {
  width: 140px;
}

.underline:hover {
  text-decoration: underline;
}

.underline #simple-menu ul {
  background: rgba(2, 6, 10, 0.9);
}

.underline #simple-menu ul a {
  text-decoration: none !important;
  color: #87c7f7 !important;
}

.underline #simple-menu ul a:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 905px) {
  nav {
    top: 0;
    width: 100%;
    border-radius: 0;
    border: none;
    height: 60px;
    background-color: rgba(2, 6, 10, 0.7);
  }
  nav .nav-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 1800px;
    margin: auto;
  }
  nav ul,
  nav ul.openNav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    background-color: transparent;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0px;
    margin: 0;
  }
  nav ul li,
  nav ul.openNav li {
    margin-right: 25px;
  }
  nav ul li:hover, nav ul li:active, nav ul li.active,
  nav ul.openNav li:hover,
  nav ul.openNav li:active,
  nav ul.openNav li.active {
    background: none;
  }
  nav ul li:hover a, nav ul li:active a, nav ul li.active a,
  nav ul.openNav li:hover a,
  nav ul.openNav li:active a,
  nav ul.openNav li.active a {
    text-decoration: underline;
  }
  nav ul li a,
  nav ul.openNav li a {
    font-size: 1.2rem;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 905px) and (min-width: 1050px) {
  nav ul li a,
  nav ul.openNav li a {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 905px) {
  nav ul li:nth-last-child(2),
  nav ul.openNav li:nth-last-child(2) {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1270px) {
  nav #nav-logo img {
    width: 260px;
  }
}

button p {
  margin: 0;
}

.button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 40px;
}

.button-container.smaller-margin {
  margin-top: 16px;
}

.button-container.no-margin {
  margin-top: 0;
}

.button-container.stacked {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 18px;
}

.button-container.gap {
  gap: 18px;
}

.button-container.nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.button-container.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.button-container.center-left {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.button-container.left {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.button-container.select-fields {
  width: 150px;
}

.button-container .MuiButtonBase-root.MuiButton-contained, .button-container .MuiButtonBase-root.MuiButton-outlined {
  width: 100%;
  min-width: 203px;
  min-height: 45px;
  padding-right: 0;
  background: none;
  position: relative;
  padding: 0;
  margin: 0;
  background-size: cover;
  border: none;
  border-radius: 0;
}

.button-container .MuiButtonBase-root.MuiButton-contained p, .button-container .MuiButtonBase-root.MuiButton-outlined p {
  font-size: 1.6rem;
  position: relative;
  top: 1px;
}

.button-container .MuiButtonBase-root.MuiButton-contained .MuiButton-label, .button-container .MuiButtonBase-root.MuiButton-outlined .MuiButton-label {
  display: inline;
}

.button-container .MuiButtonBase-root.MuiButton-contained {
  background: url("../imagesV2/buttonStyles/Cresco_Primary_Default.svg") no-repeat;
  background-size: cover;
}

.button-container .MuiButtonBase-root.MuiButton-contained p {
  color: #001f3b;
  font-family: Poppins-Semibold;
}

.button-container .MuiButtonBase-root.MuiButton-contained:hover, .button-container .MuiButtonBase-root.MuiButton-contained:focus {
  background: url("../imagesV2/buttonStyles/Cresco_Primary_Hover.svg") no-repeat;
  background-size: cover;
}

.button-container .MuiButtonBase-root.MuiButton-outlined {
  color: #b9e0f7;
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Default.svg") no-repeat;
  background-size: cover;
}

.button-container .MuiButtonBase-root.MuiButton-outlined p {
  font-family: Poppins-Semibold;
}

.button-container .MuiButtonBase-root.MuiButton-outlined:hover, .button-container .MuiButtonBase-root.MuiButton-outlined:focus {
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Hover.svg") no-repeat;
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Active.svg") no-repeat;
  background-size: cover;
}

.button-container.llr-button-container .MuiButtonBase-root.MuiButton-contained {
  background: url("../imagesV2/buttonStyles/Cresco_LLR_Primary_Default.svg") no-repeat;
  background-size: cover;
}

.button-container.llr-button-container .MuiButtonBase-root.MuiButton-contained p {
  color: #001f3b;
  font-family: Poppins-Semibold;
}

.button-container.llr-button-container .MuiButtonBase-root.MuiButton-contained:hover, .button-container.llr-button-container .MuiButtonBase-root.MuiButton-contained:focus {
  background: url("../imagesV2/buttonStyles/Cresco_LLR_Primary_Hover.svg") no-repeat;
  background-size: cover;
}

.button-container .outlined-button {
  cursor: pointer;
  width: 100%;
  min-width: 203px;
  min-height: 45px;
  padding-right: 0;
  background: none;
  position: relative;
  padding: 0;
  margin: 0;
  background-size: cover;
  border: none;
  border-radius: 0;
  color: #b9e0f7;
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Default.svg") no-repeat;
  background-size: cover;
  font-family: Poppins-Semibold;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  width: 203px;
  height: 45px;
}

.button-container .outlined-button p {
  font-size: 1.6rem;
  position: relative;
  top: 1px;
}

.button-container .outlined-button p {
  font-family: Poppins-Semibold;
}

.button-container .outlined-button:hover, .button-container .outlined-button:focus {
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Hover.svg") no-repeat;
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Active.svg") no-repeat;
  background-size: cover;
}

.button-container .contained-button {
  cursor: pointer;
  width: 100%;
  min-width: 203px;
  min-height: 45px;
  padding-right: 0;
  background: none;
  position: relative;
  padding: 0;
  margin: 0;
  background-size: cover;
  border: none;
  border-radius: 0;
  background: url("../imagesV2/buttonStyles/Cresco_Primary_Default.svg") no-repeat;
  background-size: cover;
  font-family: Poppins-Semibold;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  width: 203px;
  height: 45px;
}

.button-container .contained-button p {
  font-size: 1.6rem;
  position: relative;
  top: 1px;
}

.button-container .contained-button p {
  color: #001f3b;
  font-family: Poppins-Semibold;
}

.button-container .contained-button:hover, .button-container .contained-button:focus {
  background: url("../imagesV2/buttonStyles/Cresco_Primary_Hover.svg") no-repeat;
  background-size: cover;
}

.button-container .MuiFormControl-root,
.button-container .MuiInputBase-root {
  width: 100%;
  min-width: 203px;
  min-height: 45px;
  padding-right: 0;
  background: none;
  position: relative;
  padding: 0;
  margin: 0;
  background-size: cover;
  border: none;
  border-radius: 0;
}

.button-container .MuiFormControl-root p,
.button-container .MuiInputBase-root p {
  font-size: 1.6rem;
  position: relative;
  top: 1px;
}

.outlined-button-style {
  display: block;
  position: relative;
  width: 204px;
  height: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 200ms linear;
  transition: 200ms linear;
  color: #b9e0f7;
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Default.svg") no-repeat;
  background-size: cover;
}

.outlined-button-style p {
  font-family: Poppins-Semibold;
}

.outlined-button-style:hover, .outlined-button-style:focus {
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Hover.svg") no-repeat;
  background: url("../imagesV2/buttonStyles/Cresco_Secondary_Active.svg") no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 900px) {
  .button-container {
    gap: 30px;
    margin-top: 80px;
  }
  .button-container.smaller-margin {
    margin-top: 32px;
  }
  .button-container.stacked {
    gap: 40px;
  }
  .button-container.center-left {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .button-container .MuiButtonBase-root.MuiButton-contained, .button-container .MuiButtonBase-root.MuiButton-outlined {
    width: 100%;
    width: 242px;
    height: 52px;
  }
  .button-container .outlined-button {
    width: 100%;
    width: 242px;
    height: 52px;
  }
  .button-container .outlined-button.small-button {
    width: 203px;
    height: 45px;
  }
}

@media only screen and (min-width: 1100px) {
  .outlined-button-style {
    width: 437px;
    height: 92px;
  }
  .button-container.select-fields {
    width: 392px;
  }
}

@media only screen and (min-width: 1400px) {
  .button-container {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}

.standard-content-section {
  margin: auto;
  max-width: 800px;
}

.gg-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.gg-4 .strain-by-id {
  width: 157px;
  min-height: 157px;
}

.standard-content-section h1,
.standard-content-section h2,
.richtext-section h1,
.richtext-section h2 {
  margin-bottom: 16px;
}

.standard-content-section .button-container,
.richtext-section .button-container {
  margin-top: 43px;
  gap: 20px;
}

.react-horizontal-scrolling-menu--item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.react-horizontal-scrolling-menu--item .strain-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.react-horizontal-scrolling-menu--item .strain-card .scale {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 220px;
}

.content-slider-mobile {
  text-align: center;
}

.content-slider-mobile .copy {
  max-width: 700px;
  margin: auto;
}

.llr-info {
  text-align: center;
}

.llr-info .llr-wrapper {
  max-width: 1150px;
  margin: auto;
}

.llr-info .copy {
  max-width: 700px;
  margin: auto;
}

.llr-info .info-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.strains-list {
  display: -ms-inline-grid;
  display: inline-grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  max-width: 1100px;
  gap: 10px 10px;
  margin-top: 40px;
}

@media only screen and (min-width: 360px) {
  .strains-list {
    -ms-grid-columns: 157px 157px;
        grid-template-columns: 157px 157px;
  }
}

@media only screen and (min-width: 500px) {
  .strains-list {
    -ms-grid-columns: 157px 157px 157px;
        grid-template-columns: 157px 157px 157px;
  }
}

@media only screen and (min-width: 900px) {
  .strains-list {
    -ms-grid-columns: (minmax(auto, 220px))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(auto, 220px));
  }
}

.terpene-finder-slice .content {
  color: white;
  text-align: center;
}

.terpene-finder-slice .content h2,
.terpene-finder-slice .content h3 {
  margin: 0;
  margin-top: 10px;
}

.terpene-finder-slice .content .content-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.terpene-finder-slice .wrapper .filters {
  text-align: center;
}

.terpene-finder-slice .wrapper .filters .filter-item {
  margin: 35px auto;
}

.terpene-finder-slice .wrapper .filters .filter-item .descriptor {
  margin-bottom: 20px;
}

.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px 15px;
}

.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .smell-btn,
.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .taste-btn {
  background: transparent;
  cursor: pointer;
  border: none;
  color: #b9e0f7;
  position: relative;
  z-index: 3;
}

.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .smell-btn.disabled,
.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .taste-btn.disabled {
  opacity: 0.3;
}

.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .smell-btn img,
.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .taste-btn img {
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
  position: relative;
  z-index: 3;
}

.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .smell-btn .terpene-list-item,
.terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .taste-btn .terpene-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: none;
  border: none;
}

.terpene-finder-slice .wrapper .selected-terpene {
  text-align: center;
}

.terpene-finder-slice .wrapper .selected-terpene .terpene-icon {
  padding: 50px 30px 40px 30px;
}

.terpene-finder-slice .wrapper h6 {
  text-align: center;
}

.terpene-finder-slice .wrapper .recommended-strains {
  margin-top: 40px;
}

.terpene-finder-slice .wrapper .recommended-strains .recommended-strain-copy {
  text-align: center;
  margin-bottom: 30px;
}

.terpene-finder-slice .wrapper .recommended-strains .strain-placeholder .placeholder-wrapper {
  width: 157px;
}

.terpene-finder-slice .wrapper .recommended-strains .strain-placeholder .placeholder-wrapper p {
  margin: 0;
}

.terpene-finder-slice .wrapper .recommended-strains .strain-placeholder .placeholder-wrapper .question {
  background-color: #001f3b;
  height: 157px;
  width: 157px;
  color: #b9e0f7;
  font-size: 4rem;
}

.terpene-finder-slice .wrapper .recommended-strains .strain-placeholder .placeholder-wrapper .copy {
  width: inherit;
  height: 43px;
  background-color: #b9e0f7;
  color: #001f3b;
  font-size: 1.4rem;
}

.terpene-finder-slice .wrapper .recommended-strains .strain-cards-desktop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  max-width: 900px;
  margin: 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.terpene-finder-slice .wrapper .recommended-strains .strain-cards-desktop .strain-image {
  width: 100%;
  margin-right: 10px;
  max-width: 400px;
}

.terpene-finder-slice .wrapper .recommended-strains .strain-cards-desktop .strain-image:last-of-type {
  margin-right: 0;
}

.terpene-finder-slice .wrapper .recommended-strains .strain-cards-desktop .strain-image img {
  width: 100%;
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.terpene-finder-slice .wrapper .recommended-strains .button-container {
  text-align: center;
  margin-bottom: 25px;
}

.double-content-section {
  max-width: 700px;
  margin: auto;
}

.double-content-section .first-content,
.double-content-section .second-content {
  text-align: center;
}

.double-content-section .first-content h2,
.double-content-section .second-content h2 {
  margin-bottom: 1.6rem;
}

.terpenes figure {
  width: 50px !important;
  height: 50px !important;
}

.terpenes .name {
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.1rem;
  color: #b9e0f7;
  margin-top: 8px;
  font-family: 'Poppins-Semibold';
}

.strain-info-section {
  max-width: 900px;
  margin: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 0 50px 50px 50px;
}

.strain-info-section p {
  margin: 0;
}

.strain-info-section .strain-info {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 70px auto;
      grid-template-columns: 70px auto;
  gap: 15px;
}

.strain-info-section .strain-info .title {
  padding-top: 10px;
}

.strain-info-section .strain-info .terpenes-wrapper .terpenes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  margin-top: 8px;
}

.strain-info-section .strain-info .terpenes-wrapper .terpenes div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.terpenes-feature-slice .content {
  padding: 80px 40px 40px 40px;
  max-width: 850px;
  margin: auto;
}

.terpenes-feature-slice .terpenes-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 160px;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section {
  display: -ms-inline-grid;
  display: inline-grid;
  -ms-grid-columns: 160px auto;
      grid-template-columns: 160px auto;
  gap: 100px;
  width: 600px;
  margin: auto;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .terpenes-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 75px 75px;
      grid-template-columns: 75px 75px;
  gap: 30px 20px;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .terpenes-list button {
  background: none;
  border: none;
  color: #b9e0f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2px;
  cursor: pointer;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-img-section {
  display: none;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content {
  color: #87c7f7;
  text-align: center;
  padding: 40px;
  border: 1px solid #87c7f7;
  min-height: 740px;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content .terpene-img {
  margin-bottom: 20px;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content .name {
  margin-bottom: 40px;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content h5 {
  font-size: 2.5rem;
  margin-top: 35px;
  margin-bottom: 10px;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content p {
  font-size: 1.6rem;
  margin-bottom: 5px;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content h5:first-of-type {
  margin-top: 20px;
}

.terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content .terpene-img {
  max-width: 200px;
}

@media only screen and (min-width: 600px) {
  .strain-by-id .img-wrapper {
    width: auto !important;
  }
}

@media only screen and (min-width: 900px) {
  .gg-4 .strain-by-id {
    width: 220px;
    min-height: 220px;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section {
    width: 850px;
  }
  .standard-content-section {
    width: 700px;
  }
  .standard-content-section h1,
  .standard-content-section h2,
  .richtext-section h1,
  .richtext-section h2 {
    margin-bottom: 32px;
  }
  .standard-content-section .button-container,
  .richtext-section .button-container {
    margin-top: 80px;
  }
  .strains-list {
    margin-top: 80px;
  }
  .llr-info h1,
  .llr-info h2 {
    font-size: 6rem;
  }
  .llr-info .copy {
    margin-bottom: 32px;
  }
  .llr-info .icons-wrapper {
    margin: auto;
  }
  .strain-info-section .strain-info figure {
    height: 75px;
    width: 75px;
  }
  .terpene-finder-slice .wrapper .filters {
    max-width: 800px;
    margin: 40px auto 100px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    position: relative;
  }
  .terpene-finder-slice .wrapper .filters .filter-item {
    margin: 0 auto;
  }
  .terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper {
    width: 350px;
  }
  .terpene-finder-slice .wrapper .filters:after {
    content: '';
    border-right: 1px solid #b9e0f7;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 54%;
    left: 25%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50%;
  }
  .terpene-finder-slice .wrapper .recommended-strains .recommended-strain-copy {
    font-size: 2rem;
  }
  .terpene-finder-slice .wrapper .recommended-strains .strain-placeholder {
    max-width: 800px;
    margin: auto;
  }
  .terpene-finder-slice .wrapper .recommended-strains .strain-placeholder .placeholder-wrapper {
    width: 220px;
    margin: auto;
  }
  .terpene-finder-slice .wrapper .recommended-strains .strain-placeholder .placeholder-wrapper .question {
    width: 220px;
    height: 220px;
    font-size: 8rem;
  }
}

@media only screen and (min-width: 1200px) {
  .content-slider-mobile {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .content-slider-mobile .llr-wrapper {
    max-width: 1280px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    gap: 30px;
  }
  .content-slider-mobile .copy {
    margin: 0;
  }
  .double-content-section .first-content {
    padding-bottom: 40px;
    padding-left: 0;
  }
  .double-content-section .second-content {
    padding-top: 40px;
    padding-left: 0;
  }
  .double-content-section .first-content,
  .double-content-section .second-content {
    text-align: left;
  }
  .double-content-section .first-content h2,
  .double-content-section .second-content h2 {
    margin-bottom: 3.2rem;
    font-size: 6rem;
  }
  .content-dram-section {
    padding: 100px 0;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 400px auto;
        grid-template-columns: 400px auto;
    gap: 60px;
    max-width: 1500px;
    margin: auto;
  }
  .strain-info-section {
    padding-top: 80px;
    max-width: 1200px;
    gap: 50px;
  }
  .strain-info-section .strain-info {
    -ms-grid-columns: 75px auto;
        grid-template-columns: 75px auto;
  }
  .strain-info-section .strain-info .title {
    font-size: 1.4rem;
    padding-top: 0;
  }
  .strain-info-section .strain-info .content {
    font-size: 3.6rem;
  }
  .terpene-finder-slice .wrapper .filters {
    max-width: 1200px;
    margin-top: 80px;
  }
  .terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper {
    width: 500px;
    gap: 30px;
  }
  .terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .smell-btn img,
  .terpene-finder-slice .wrapper .filters .filter-item .btn-wrapper .taste-btn img {
    width: 75px;
    height: 75px;
  }
  .terpene-finder-slice .wrapper .strain-placeholder {
    max-width: 800px;
    margin: auto;
  }
}

@media only screen and (min-width: 1300px) {
  .terpenes-feature-slice .content {
    padding: 140px 40px 60px;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section {
    -ms-grid-columns: auto 500px 347px;
        grid-template-columns: auto 500px 347px;
    gap: 75px;
    position: relative;
    left: 60px;
    width: 1100px;
  }
}

@media only screen and (min-width: 1300px) and (min-width: 1450px) {
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section {
    -ms-grid-columns: auto 500px 400px;
        grid-template-columns: auto 500px 400px;
  }
}

@media only screen and (min-width: 1300px) {
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .terpenes-list {
    gap: 30px;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-img-section {
    display: block;
    padding-top: 80px;
    border: 1px solid #b9e0f7;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-img-section figure {
    width: 350px;
    margin: auto;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-img-section .name {
    padding-top: 60px;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content {
    border: none;
    text-align: left;
    padding: 0;
    margin-left: -30px;
    min-height: 620px;
    padding-right: 50px;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content .terpene-img,
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content .name {
    display: none;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content h5 {
    font-size: 3rem;
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section .selected-terpene-content h5:first-of-type {
    margin-top: 0px;
  }
}

.floating-faq {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.floating-faq button {
  border-radius: 0;
  background: #001f3b;
  border: 1px solid #87c7f7;
  color: #87c7f7;
  padding: 5px;
}

.floating-faq button span {
  text-transform: none;
}

.faq-module-wrapper {
  padding: 20px;
  background: #b9e0f7;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  overflow: scroll;
}

.faq-module-wrapper h2 {
  font-size: 20px;
  color: #001f3b;
  text-transform: none;
  letter-spacing: 0px;
}

.faq-module-wrapper p {
  font-size: 14px;
  color: #001f3b;
}

.faq-module-wrapper .MuiButton-root {
  border-radius: 0;
}

.faq-module-wrapper .MuiOutlinedInput-notchedOutline {
  border-color: #001f3b !important;
}

.faq-module-wrapper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #001f3b !important;
}

.faq-module-wrapper .PrivateNotchedOutline-legendLabelled-9 > span,
.faq-module-wrapper .MuiInputLabel-outlined {
  color: #001f3b !important;
}

.faq-module-wrapper .MuiInputLabel-outlined {
  top: -1px;
}

.faq-module-wrapper .MuiOutlinedInput-root {
  width: 150%;
  color: #001f3b;
}

@media only screen and (min-width: 390px) {
  .faq-module-wrapper .MuiOutlinedInput-root {
    width: 170%;
  }
}

@media only screen and (min-width: 800px) {
  .faq-module-wrapper .MuiOutlinedInput-root {
    width: 200%;
  }
}

.faq-module-wrapper .MuiTableCell-root {
  padding: 10px 0px;
  border: 0;
}

.faq-module-wrapper .MuiTableCell-root .MuiBox-root {
  margin: 0;
}

.faq-module-wrapper .MuiTableCell-root .MuiBox-root h2 {
  margin: 0;
  cursor: pointer;
}

.faq-module-wrapper .MuiTableCell-root .MuiBox-root.answer {
  padding: 5px 0px;
}

.faq-module-wrapper .MuiSvgIcon-root {
  color: #001f3b;
  fill: #001f3b;
}

@media only screen and (min-width: 1400px) {
  .strains-list {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
    margin-top: 80px;
  }
  .content-slider-mobile .llr-wrapper {
    gap: 100px;
  }
}

@media only screen and (min-width: 1600px) {
  .content-slider-mobile .llr-wrapper {
    gap: 50px;
  }
  .strain-info-section .strain-info .title {
    font-size: 2.6rem;
  }
  .strain-info-section .strain-info .content {
    font-size: 4rem;
  }
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.text-content ul {
  padding-left: 40px;
  max-width: 1000px;
  margin: 1.5rem auto;
}

.text-content ul li {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

.text-content ul li a {
  color: #f4c01a;
  text-decoration: underline;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .section-half {
    padding: 100px 50px;
  }
  .section-half .wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .section-half .wrapper .text-content,
  .section-half .wrapper .img-content {
    width: 50%;
  }
  .section-half .wrapper .text-content {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-bottom: 0;
    margin-left: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animation {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}

.animation.yas {
  opacity: 1;
}

.animation.yas .hero-section .background-image,
.animation.yas .hero-section .background-video,
.animation.yas .hero-section .hero-carousel {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.animation.yas .hero-section .text-content {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.animation.yas .hero-section .product-slides-container:hover img {
  -webkit-transform: scale(0.9) !important;
          transform: scale(0.9) !important;
}

.animation.yas .hero-section .product-slides-container img {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.animation.yas .hero-section .product-slides-container img:nth-of-type(1) {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.animation.yas .hero-section .product-slides-container img:nth-of-type(3) {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.animation.yas .hero-section .product-slides-container img:nth-of-type(5) {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.animation.yas .hero-section .product-slides-container img:hover {
  -webkit-transform: scale(1.2) !important;
          transform: scale(1.2) !important;
}

.text-section {
  background-size: cover !important;
  background-position: center center !important;
}

.text-section .wrapper *:last-of-type {
  margin-bottom: 0;
}

.text-section .wrapper p {
  margin: 20px 0px;
}

.text-section .wrapper .image-wrapper {
  margin-top: 40px;
}

.text-section .wrapper .image-wrapper img {
  max-width: 80%;
  margin: 0 auto;
}

@media only screen and (min-width: 600px) {
  .text-section {
    padding: 114px 48px;
  }
}

.text-image-section .wrapper {
  max-width: 1300px;
}

.text-image-section .wrapper .content-wrapper {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
}

.text-image-section .wrapper .button-wrapper {
  margin-top: 24px;
}

.text-image-section .wrapper .image-wrapper {
  position: relative;
}

.text-image-section .wrapper .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.text-image-section.animate .wrapper .content-wrapper {
  -webkit-transform: translateX(100px) scale(0.9);
          transform: translateX(100px) scale(0.9);
  opacity: 0;
  -webkit-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
}

.text-image-section.animate .wrapper .image-wrapper {
  -webkit-transform: translateX(-100px) scale(0.9);
          transform: translateX(-100px) scale(0.9);
  opacity: 0;
  -webkit-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
}

.text-image-section.animate .wrapper .lead-image .content-wrapper {
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
}

.text-image-section.animate .wrapper .lead-image .image-wrapper {
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
}

.text-image-section.animate.animation-active .wrapper .content-wrapper,
.text-image-section.animate.animation-active .wrapper .image-wrapper {
  -webkit-transform: translateX(0) scale(1);
          transform: translateX(0) scale(1);
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .text-image-section .wrapper .image-wrapper {
    min-width: 50%;
    max-width: 50%;
    margin-left: 0;
    margin-top: 0;
    max-width: none;
  }
  .text-image-section .wrapper .image-wrapper img {
    margin: 40px 0px;
  }
  .text-image-section .wrapper.full-width {
    max-width: none !important;
  }
  .text-image-section .wrapper.full-width .image-wrapper {
    margin-left: 0;
    margin-top: 0;
    max-width: none;
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
  }
  .text-image-section .wrapper.full-width .image-wrapper img {
    margin: 0 !important;
  }
  .text-image-section .wrapper.full-width .content-wrapper .content-wrapper-inner {
    max-width: 550px;
    width: 100%;
    float: right;
  }
  .text-image-section .wrapper .content-wrapper {
    text-align: inherit;
  }
}

@media only screen and (min-width: 768px) {
  .button-wrapper .button {
    width: auto !important;
  }
}

.section-padding {
  padding: 20px;
}

.section-padding-2 {
  padding: 30px;
}

@media only screen and (min-width: 768px) {
  .section-padding {
    padding: 40px;
  }
}

@media only screen and (min-width: 1279px) {
  .section-padding {
    padding: 60px;
  }
}

.animate {
  -webkit-transform: translateY(100px) scale(0.9);
          transform: translateY(100px) scale(0.9);
  -webkit-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
  opacity: 0;
}

.animate.animation-active {
  -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
  opacity: 1;
}

.text-slice .wrapper .text-slice-logo {
  width: 100%;
  max-width: 45px;
  margin: 10px auto 50px;
}

@media only screen and (min-width: 1000px) {
  .text-slice .wrapper .text-slice-logo {
    margin: 50px auto;
  }
}

.text-slice .wrapper h3 {
  line-height: 1.2;
}

.text-slice .wrapper .icon {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 47px;
}

.text-slice .wrapper .icon img {
  width: 100%;
  height: auto;
}

.text-slice .wrapper .text-content {
  max-width: 1000px;
  margin: 0 auto;
}

.text-slice .wrapper .buttons {
  text-align: center;
  margin-top: 20px;
}

.full-width-image {
  width: 100%;
  height: auto;
}

.carousel .slide {
  background: none;
}

.carousel .thumbs-wrapper {
  margin: 0;
}

.carousel .thumbs-wrapper .control-arrow {
  display: none !important;
}

.carousel .thumbs-wrapper .thumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
          transform: translate3d(0px, 0px, 0px) !important;
  margin: 0;
}

.carousel .thumbs-wrapper .thumbs .thumb {
  margin-right: 5px;
  margin-top: 5px;
  border: 1px solid white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  width: 100% !important;
  cursor: pointer;
}

.carousel .thumbs-wrapper .thumbs .thumb:last-of-type {
  margin-right: 0;
}

.carousel .thumbs-wrapper .thumbs .thumb:hover {
  border: 1px solid #f4c01a;
}

.carousel .thumbs-wrapper .thumbs .thumb.selected {
  border: 1px solid #f4c01a;
}

.control-arrow {
  background: url("../imagesV2/icons/arrow.svg") no-repeat !important;
  background-size: contain !important;
  background-position: center center !important;
  width: 30px;
}

.control-arrow:before {
  display: none !important;
}

.control-arrow.control-prev {
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
}

.questions-slice .desktop-copy {
  display: none;
}

.questions-slice .questions-carousel .slide {
  background: none;
  padding: 0px 20px;
}

.questions-slice .questions-carousel .slide .image-content {
  margin-bottom: 20px;
}

.questions-slice .questions-carousel .slide .image-content img {
  width: 80%;
  margin: 0 auto;
}

.questions-slice .questions-carousel .slide .text-content {
  margin-bottom: 20px;
}

.text-background-slice {
  background-size: cover !important;
  background-position: center center !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(100% - 10px);
  min-height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 40px 20px;
}

.text-background-slice .wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.text-background-slice .wrapper.align-bottom {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.text-background-slice .wrapper .foreground-image {
  width: 80%;
  margin: 0 auto;
}

.text-background-slice .wrapper .text-content {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .text-background-slice .wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .text-background-slice .wrapper .text-content {
    width: 50%;
  }
  .text-background-slice .wrapper.align-right {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

.video-slice video {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .slice-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .slice-wrapper .slice-single {
    width: 100%;
  }
  .slice-wrapper .slice-single.half-width {
    width: 50%;
  }
  .slice-wrapper .slice-single.half-width .text-background-slice {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 50px 25px;
  }
  .slice-wrapper .slice-single.half-width .text-background-slice .wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .slice-wrapper .slice-single.half-width .text-background-slice .wrapper .text-content {
    max-width: none !important;
    width: 100%;
  }
}

.column-section .wrapper .column-wrapper .column .column-image {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.column-section .wrapper .column-wrapper .column .column-image img {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .column-section {
    overflow: auto;
  }
  .column-section .wrapper .text-content {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 40px;
  }
  .column-section .wrapper .column-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .column-section .wrapper .column-wrapper .column {
    width: 100%;
  }
  .column-section .wrapper .column-wrapper .column.box-padding {
    padding-top: 0;
  }
}

.text-slider-slice .wrapper .mobile-image-container {
  width: 100%;
  height: 100%;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.text-slider-slice .wrapper .mobile-image-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.text-slider-slice .wrapper .slider-container .carousel .slide {
  text-align: left;
}

.text-slider-slice .wrapper .slider-container .carousel .slide .box-padding {
  padding: 40px 60px;
}

.text-slider-slice .wrapper .slider-container .carousel .control-arrow {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.text-slider-slice .wrapper .slider-container .carousel .control-dots {
  margin: 0px 60px;
  width: 100%;
  width: calc(100% - 120px);
  margin-bottom: 20px !important;
}

@media only screen and (min-width: 768px) {
  .text-slider-slice .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .text-slider-slice .wrapper .image-container {
    width: 30%;
  }
  .text-slider-slice .wrapper .image-container img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center right;
       object-position: center right;
  }
  .text-slider-slice .wrapper .slider-container {
    width: 70%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.michaelthompson-page .section-padding,
.socialjustice-page .section-padding,
.expungement-events-page .section-padding,
.socialjustice-page .section-padding {
  padding: 40px 20px;
}

.michaelthompson-page .hero-section,
.socialjustice-page .hero-section,
.expungement-events-page .hero-section,
.socialjustice-page .hero-section {
  margin-bottom: 10px;
}

.michaelthompson-page .hero-section .text-content,
.socialjustice-page .hero-section .text-content,
.expungement-events-page .hero-section .text-content,
.socialjustice-page .hero-section .text-content {
  max-width: 700px;
}

.michaelthompson-page .hero-section a:nth-of-type(1) button,
.socialjustice-page .hero-section a:nth-of-type(1) button,
.expungement-events-page .hero-section a:nth-of-type(1) button,
.socialjustice-page .hero-section a:nth-of-type(1) button {
  border: 2px solid #f4c01a !important;
  color: white !important;
  background: #f4c01a !important;
}

.michaelthompson-page .hero-section a:nth-of-type(1) button:hover,
.socialjustice-page .hero-section a:nth-of-type(1) button:hover,
.expungement-events-page .hero-section a:nth-of-type(1) button:hover,
.socialjustice-page .hero-section a:nth-of-type(1) button:hover {
  background: white !important;
  border: 2px solid white !important;
  color: pink;
}

.michaelthompson-page .special-image-content.special-image-hero .section-padding,
.socialjustice-page .special-image-content.special-image-hero .section-padding,
.expungement-events-page .special-image-content.special-image-hero .section-padding,
.socialjustice-page .special-image-content.special-image-hero .section-padding {
  padding: 60px;
}

.michaelthompson-page .special-image-content.special-image-yellow-background .wrapper .text-content p,
.michaelthompson-page .special-image-content.special-image-yellow-background .wrapper .text-content h2,
.socialjustice-page .special-image-content.special-image-yellow-background .wrapper .text-content p,
.socialjustice-page .special-image-content.special-image-yellow-background .wrapper .text-content h2,
.expungement-events-page .special-image-content.special-image-yellow-background .wrapper .text-content p,
.expungement-events-page .special-image-content.special-image-yellow-background .wrapper .text-content h2,
.socialjustice-page .special-image-content.special-image-yellow-background .wrapper .text-content p,
.socialjustice-page .special-image-content.special-image-yellow-background .wrapper .text-content h2 {
  color: #001f3b !important;
}

.michaelthompson-page .special-image-content .text-content,
.socialjustice-page .special-image-content .text-content,
.expungement-events-page .special-image-content .text-content,
.socialjustice-page .special-image-content .text-content {
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.michaelthompson-page .special-image-content .text-content span,
.socialjustice-page .special-image-content .text-content span,
.expungement-events-page .special-image-content .text-content span,
.socialjustice-page .special-image-content .text-content span {
  display: inline;
}

.michaelthompson-page .special-image-content .text-content p,
.socialjustice-page .special-image-content .text-content p,
.expungement-events-page .special-image-content .text-content p,
.socialjustice-page .special-image-content .text-content p {
  font-size: 18px;
  margin-top: 40px;
}

.michaelthompson-page .special-image-content .text-content a,
.socialjustice-page .special-image-content .text-content a,
.expungement-events-page .special-image-content .text-content a,
.socialjustice-page .special-image-content .text-content a {
  color: #f4c01a;
}

.michaelthompson-page .special-image-content .text-content .logos,
.socialjustice-page .special-image-content .text-content .logos,
.expungement-events-page .special-image-content .text-content .logos,
.socialjustice-page .special-image-content .text-content .logos {
  display: inline;
  width: 100%;
  max-width: 540px;
  margin-top: 40px;
}

.michaelthompson-page .special-image-content .text-content h2.orange-background,
.socialjustice-page .special-image-content .text-content h2.orange-background,
.expungement-events-page .special-image-content .text-content h2.orange-background,
.socialjustice-page .special-image-content .text-content h2.orange-background {
  padding-top: 10px;
}

.michaelthompson-page .special-image-content .text-content h2.orange-background:before,
.socialjustice-page .special-image-content .text-content h2.orange-background:before,
.expungement-events-page .special-image-content .text-content h2.orange-background:before,
.socialjustice-page .special-image-content .text-content h2.orange-background:before {
  width: 200%;
  left: -50%;
  z-index: -1;
}

.michaelthompson-page .special-image-content .text-content h4,
.socialjustice-page .special-image-content .text-content h4,
.expungement-events-page .special-image-content .text-content h4,
.socialjustice-page .special-image-content .text-content h4 {
  text-transform: none !important;
  font-size: 30px;
}

.michaelthompson-page .special-image-content .text-content h5,
.socialjustice-page .special-image-content .text-content h5,
.expungement-events-page .special-image-content .text-content h5,
.socialjustice-page .special-image-content .text-content h5 {
  font-size: 50px;
  line-height: 60px;
  text-transform: none;
  margin-top: 40px;
}

.michaelthompson-page .special-image-content .text-content h6,
.socialjustice-page .special-image-content .text-content h6,
.expungement-events-page .special-image-content .text-content h6,
.socialjustice-page .special-image-content .text-content h6 {
  margin-top: 20px;
  text-transform: none;
  font-size: 20px;
}

.michaelthompson-page .special-image-content .image-content img,
.socialjustice-page .special-image-content .image-content img,
.expungement-events-page .special-image-content .image-content img,
.socialjustice-page .special-image-content .image-content img {
  width: 100%;
}

.michaelthompson-page .special-image-content .line svg,
.socialjustice-page .special-image-content .line svg,
.expungement-events-page .special-image-content .line svg,
.socialjustice-page .special-image-content .line svg {
  display: none;
}

.michaelthompson-page .cta-slice,
.michaelthompson-page .fact-slice,
.michaelthompson-page .partner-slice,
.michaelthompson-page .video-thumbs-slice,
.socialjustice-page .cta-slice,
.socialjustice-page .fact-slice,
.socialjustice-page .partner-slice,
.socialjustice-page .video-thumbs-slice,
.expungement-events-page .cta-slice,
.expungement-events-page .fact-slice,
.expungement-events-page .partner-slice,
.expungement-events-page .video-thumbs-slice,
.socialjustice-page .cta-slice,
.socialjustice-page .fact-slice,
.socialjustice-page .partner-slice,
.socialjustice-page .video-thumbs-slice {
  margin: 0;
}

.michaelthompson-page .cta-slice .wrapper,
.michaelthompson-page .fact-slice .wrapper,
.michaelthompson-page .partner-slice .wrapper,
.michaelthompson-page .video-thumbs-slice .wrapper,
.socialjustice-page .cta-slice .wrapper,
.socialjustice-page .fact-slice .wrapper,
.socialjustice-page .partner-slice .wrapper,
.socialjustice-page .video-thumbs-slice .wrapper,
.expungement-events-page .cta-slice .wrapper,
.expungement-events-page .fact-slice .wrapper,
.expungement-events-page .partner-slice .wrapper,
.expungement-events-page .video-thumbs-slice .wrapper,
.socialjustice-page .cta-slice .wrapper,
.socialjustice-page .fact-slice .wrapper,
.socialjustice-page .partner-slice .wrapper,
.socialjustice-page .video-thumbs-slice .wrapper {
  max-width: 1085px;
}

.michaelthompson-page .cta-slice span.orange-color,
.michaelthompson-page .fact-slice span.orange-color,
.michaelthompson-page .partner-slice span.orange-color,
.michaelthompson-page .video-thumbs-slice span.orange-color,
.socialjustice-page .cta-slice span.orange-color,
.socialjustice-page .fact-slice span.orange-color,
.socialjustice-page .partner-slice span.orange-color,
.socialjustice-page .video-thumbs-slice span.orange-color,
.expungement-events-page .cta-slice span.orange-color,
.expungement-events-page .fact-slice span.orange-color,
.expungement-events-page .partner-slice span.orange-color,
.expungement-events-page .video-thumbs-slice span.orange-color,
.socialjustice-page .cta-slice span.orange-color,
.socialjustice-page .fact-slice span.orange-color,
.socialjustice-page .partner-slice span.orange-color,
.socialjustice-page .video-thumbs-slice span.orange-color {
  color: #f4c01a;
  display: inline;
}

.michaelthompson-page .cta-slice.section-padding,
.michaelthompson-page .fact-slice.section-padding,
.michaelthompson-page .partner-slice.section-padding,
.michaelthompson-page .video-thumbs-slice.section-padding,
.socialjustice-page .cta-slice.section-padding,
.socialjustice-page .fact-slice.section-padding,
.socialjustice-page .partner-slice.section-padding,
.socialjustice-page .video-thumbs-slice.section-padding,
.expungement-events-page .cta-slice.section-padding,
.expungement-events-page .fact-slice.section-padding,
.expungement-events-page .partner-slice.section-padding,
.expungement-events-page .video-thumbs-slice.section-padding,
.socialjustice-page .cta-slice.section-padding,
.socialjustice-page .fact-slice.section-padding,
.socialjustice-page .partner-slice.section-padding,
.socialjustice-page .video-thumbs-slice.section-padding {
  padding: 100px 60px;
}

.michaelthompson-page .cta-slice .text-content,
.michaelthompson-page .fact-slice .text-content,
.michaelthompson-page .partner-slice .text-content,
.michaelthompson-page .video-thumbs-slice .text-content,
.socialjustice-page .cta-slice .text-content,
.socialjustice-page .fact-slice .text-content,
.socialjustice-page .partner-slice .text-content,
.socialjustice-page .video-thumbs-slice .text-content,
.expungement-events-page .cta-slice .text-content,
.expungement-events-page .fact-slice .text-content,
.expungement-events-page .partner-slice .text-content,
.expungement-events-page .video-thumbs-slice .text-content,
.socialjustice-page .cta-slice .text-content,
.socialjustice-page .fact-slice .text-content,
.socialjustice-page .partner-slice .text-content,
.socialjustice-page .video-thumbs-slice .text-content {
  text-align: center;
}

.michaelthompson-page .cta-slice .text-content p,
.michaelthompson-page .fact-slice .text-content p,
.michaelthompson-page .partner-slice .text-content p,
.michaelthompson-page .video-thumbs-slice .text-content p,
.socialjustice-page .cta-slice .text-content p,
.socialjustice-page .fact-slice .text-content p,
.socialjustice-page .partner-slice .text-content p,
.socialjustice-page .video-thumbs-slice .text-content p,
.expungement-events-page .cta-slice .text-content p,
.expungement-events-page .fact-slice .text-content p,
.expungement-events-page .partner-slice .text-content p,
.expungement-events-page .video-thumbs-slice .text-content p,
.socialjustice-page .cta-slice .text-content p,
.socialjustice-page .fact-slice .text-content p,
.socialjustice-page .partner-slice .text-content p,
.socialjustice-page .video-thumbs-slice .text-content p {
  margin-top: 30px;
}

.michaelthompson-page .cta-slice .text-content p a,
.michaelthompson-page .fact-slice .text-content p a,
.michaelthompson-page .partner-slice .text-content p a,
.michaelthompson-page .video-thumbs-slice .text-content p a,
.socialjustice-page .cta-slice .text-content p a,
.socialjustice-page .fact-slice .text-content p a,
.socialjustice-page .partner-slice .text-content p a,
.socialjustice-page .video-thumbs-slice .text-content p a,
.expungement-events-page .cta-slice .text-content p a,
.expungement-events-page .fact-slice .text-content p a,
.expungement-events-page .partner-slice .text-content p a,
.expungement-events-page .video-thumbs-slice .text-content p a,
.socialjustice-page .cta-slice .text-content p a,
.socialjustice-page .fact-slice .text-content p a,
.socialjustice-page .partner-slice .text-content p a,
.socialjustice-page .video-thumbs-slice .text-content p a {
  color: #f4c01a;
}

.michaelthompson-page .cta-slice .text-content h2,
.michaelthompson-page .fact-slice .text-content h2,
.michaelthompson-page .partner-slice .text-content h2,
.michaelthompson-page .video-thumbs-slice .text-content h2,
.socialjustice-page .cta-slice .text-content h2,
.socialjustice-page .fact-slice .text-content h2,
.socialjustice-page .partner-slice .text-content h2,
.socialjustice-page .video-thumbs-slice .text-content h2,
.expungement-events-page .cta-slice .text-content h2,
.expungement-events-page .fact-slice .text-content h2,
.expungement-events-page .partner-slice .text-content h2,
.expungement-events-page .video-thumbs-slice .text-content h2,
.socialjustice-page .cta-slice .text-content h2,
.socialjustice-page .fact-slice .text-content h2,
.socialjustice-page .partner-slice .text-content h2,
.socialjustice-page .video-thumbs-slice .text-content h2 {
  position: relative;
}

.michaelthompson-page .cta-slice .text-content h2:before,
.michaelthompson-page .fact-slice .text-content h2:before,
.michaelthompson-page .partner-slice .text-content h2:before,
.michaelthompson-page .video-thumbs-slice .text-content h2:before,
.socialjustice-page .cta-slice .text-content h2:before,
.socialjustice-page .fact-slice .text-content h2:before,
.socialjustice-page .partner-slice .text-content h2:before,
.socialjustice-page .video-thumbs-slice .text-content h2:before,
.expungement-events-page .cta-slice .text-content h2:before,
.expungement-events-page .fact-slice .text-content h2:before,
.expungement-events-page .partner-slice .text-content h2:before,
.expungement-events-page .video-thumbs-slice .text-content h2:before,
.socialjustice-page .cta-slice .text-content h2:before,
.socialjustice-page .fact-slice .text-content h2:before,
.socialjustice-page .partner-slice .text-content h2:before,
.socialjustice-page .video-thumbs-slice .text-content h2:before {
  content: '';
  background: #f4c01a;
  width: 170px;
  height: 8px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -85px;
}

.michaelthompson-page .cta-slice .fact-slider,
.michaelthompson-page .fact-slice .fact-slider,
.michaelthompson-page .partner-slice .fact-slider,
.michaelthompson-page .video-thumbs-slice .fact-slider,
.socialjustice-page .cta-slice .fact-slider,
.socialjustice-page .fact-slice .fact-slider,
.socialjustice-page .partner-slice .fact-slider,
.socialjustice-page .video-thumbs-slice .fact-slider,
.expungement-events-page .cta-slice .fact-slider,
.expungement-events-page .fact-slice .fact-slider,
.expungement-events-page .partner-slice .fact-slider,
.expungement-events-page .video-thumbs-slice .fact-slider,
.socialjustice-page .cta-slice .fact-slider,
.socialjustice-page .fact-slice .fact-slider,
.socialjustice-page .partner-slice .fact-slider,
.socialjustice-page .video-thumbs-slice .fact-slider {
  background: #f4c01a;
  margin-top: 30px;
}

.michaelthompson-page .cta-slice .fact-slider .slide,
.michaelthompson-page .fact-slice .fact-slider .slide,
.michaelthompson-page .partner-slice .fact-slider .slide,
.michaelthompson-page .video-thumbs-slice .fact-slider .slide,
.socialjustice-page .cta-slice .fact-slider .slide,
.socialjustice-page .fact-slice .fact-slider .slide,
.socialjustice-page .partner-slice .fact-slider .slide,
.socialjustice-page .video-thumbs-slice .fact-slider .slide,
.expungement-events-page .cta-slice .fact-slider .slide,
.expungement-events-page .fact-slice .fact-slider .slide,
.expungement-events-page .partner-slice .fact-slider .slide,
.expungement-events-page .video-thumbs-slice .fact-slider .slide,
.socialjustice-page .cta-slice .fact-slider .slide,
.socialjustice-page .fact-slice .fact-slider .slide,
.socialjustice-page .partner-slice .fact-slider .slide,
.socialjustice-page .video-thumbs-slice .fact-slider .slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.michaelthompson-page .cta-slice .fact-slider .fact-slide,
.michaelthompson-page .fact-slice .fact-slider .fact-slide,
.michaelthompson-page .partner-slice .fact-slider .fact-slide,
.michaelthompson-page .video-thumbs-slice .fact-slider .fact-slide,
.socialjustice-page .cta-slice .fact-slider .fact-slide,
.socialjustice-page .fact-slice .fact-slider .fact-slide,
.socialjustice-page .partner-slice .fact-slider .fact-slide,
.socialjustice-page .video-thumbs-slice .fact-slider .fact-slide,
.expungement-events-page .cta-slice .fact-slider .fact-slide,
.expungement-events-page .fact-slice .fact-slider .fact-slide,
.expungement-events-page .partner-slice .fact-slider .fact-slide,
.expungement-events-page .video-thumbs-slice .fact-slider .fact-slide,
.socialjustice-page .cta-slice .fact-slider .fact-slide,
.socialjustice-page .fact-slice .fact-slider .fact-slide,
.socialjustice-page .partner-slice .fact-slider .fact-slide,
.socialjustice-page .video-thumbs-slice .fact-slider .fact-slide {
  padding: 35px;
}

.michaelthompson-page .cta-slice .fact-slider .fact-slide p,
.michaelthompson-page .cta-slice .fact-slider .fact-slide a,
.michaelthompson-page .fact-slice .fact-slider .fact-slide p,
.michaelthompson-page .fact-slice .fact-slider .fact-slide a,
.michaelthompson-page .partner-slice .fact-slider .fact-slide p,
.michaelthompson-page .partner-slice .fact-slider .fact-slide a,
.michaelthompson-page .video-thumbs-slice .fact-slider .fact-slide p,
.michaelthompson-page .video-thumbs-slice .fact-slider .fact-slide a,
.socialjustice-page .cta-slice .fact-slider .fact-slide p,
.socialjustice-page .cta-slice .fact-slider .fact-slide a,
.socialjustice-page .fact-slice .fact-slider .fact-slide p,
.socialjustice-page .fact-slice .fact-slider .fact-slide a,
.socialjustice-page .partner-slice .fact-slider .fact-slide p,
.socialjustice-page .partner-slice .fact-slider .fact-slide a,
.socialjustice-page .video-thumbs-slice .fact-slider .fact-slide p,
.socialjustice-page .video-thumbs-slice .fact-slider .fact-slide a,
.expungement-events-page .cta-slice .fact-slider .fact-slide p,
.expungement-events-page .cta-slice .fact-slider .fact-slide a,
.expungement-events-page .fact-slice .fact-slider .fact-slide p,
.expungement-events-page .fact-slice .fact-slider .fact-slide a,
.expungement-events-page .partner-slice .fact-slider .fact-slide p,
.expungement-events-page .partner-slice .fact-slider .fact-slide a,
.expungement-events-page .video-thumbs-slice .fact-slider .fact-slide p,
.expungement-events-page .video-thumbs-slice .fact-slider .fact-slide a,
.socialjustice-page .cta-slice .fact-slider .fact-slide p,
.socialjustice-page .cta-slice .fact-slider .fact-slide a,
.socialjustice-page .fact-slice .fact-slider .fact-slide p,
.socialjustice-page .fact-slice .fact-slider .fact-slide a,
.socialjustice-page .partner-slice .fact-slider .fact-slide p,
.socialjustice-page .partner-slice .fact-slider .fact-slide a,
.socialjustice-page .video-thumbs-slice .fact-slider .fact-slide p,
.socialjustice-page .video-thumbs-slice .fact-slider .fact-slide a {
  color: #001f3b !important;
}

.michaelthompson-page .cta-slice .partners,
.michaelthompson-page .fact-slice .partners,
.michaelthompson-page .partner-slice .partners,
.michaelthompson-page .video-thumbs-slice .partners,
.socialjustice-page .cta-slice .partners,
.socialjustice-page .fact-slice .partners,
.socialjustice-page .partner-slice .partners,
.socialjustice-page .video-thumbs-slice .partners,
.expungement-events-page .cta-slice .partners,
.expungement-events-page .fact-slice .partners,
.expungement-events-page .partner-slice .partners,
.expungement-events-page .video-thumbs-slice .partners,
.socialjustice-page .cta-slice .partners,
.socialjustice-page .fact-slice .partners,
.socialjustice-page .partner-slice .partners,
.socialjustice-page .video-thumbs-slice .partners {
  margin-top: 80px;
}

.michaelthompson-page .cta-slice .partners .control-arrow,
.michaelthompson-page .fact-slice .partners .control-arrow,
.michaelthompson-page .partner-slice .partners .control-arrow,
.michaelthompson-page .video-thumbs-slice .partners .control-arrow,
.socialjustice-page .cta-slice .partners .control-arrow,
.socialjustice-page .fact-slice .partners .control-arrow,
.socialjustice-page .partner-slice .partners .control-arrow,
.socialjustice-page .video-thumbs-slice .partners .control-arrow,
.expungement-events-page .cta-slice .partners .control-arrow,
.expungement-events-page .fact-slice .partners .control-arrow,
.expungement-events-page .partner-slice .partners .control-arrow,
.expungement-events-page .video-thumbs-slice .partners .control-arrow,
.socialjustice-page .cta-slice .partners .control-arrow,
.socialjustice-page .fact-slice .partners .control-arrow,
.socialjustice-page .partner-slice .partners .control-arrow,
.socialjustice-page .video-thumbs-slice .partners .control-arrow {
  -webkit-filter: brightness(0) invert(1) !important;
          filter: brightness(0) invert(1) !important;
}

.michaelthompson-page .cta-slice .partners .partner-slide .partner-logo-link,
.michaelthompson-page .fact-slice .partners .partner-slide .partner-logo-link,
.michaelthompson-page .partner-slice .partners .partner-slide .partner-logo-link,
.michaelthompson-page .video-thumbs-slice .partners .partner-slide .partner-logo-link,
.socialjustice-page .cta-slice .partners .partner-slide .partner-logo-link,
.socialjustice-page .fact-slice .partners .partner-slide .partner-logo-link,
.socialjustice-page .partner-slice .partners .partner-slide .partner-logo-link,
.socialjustice-page .video-thumbs-slice .partners .partner-slide .partner-logo-link,
.expungement-events-page .cta-slice .partners .partner-slide .partner-logo-link,
.expungement-events-page .fact-slice .partners .partner-slide .partner-logo-link,
.expungement-events-page .partner-slice .partners .partner-slide .partner-logo-link,
.expungement-events-page .video-thumbs-slice .partners .partner-slide .partner-logo-link,
.socialjustice-page .cta-slice .partners .partner-slide .partner-logo-link,
.socialjustice-page .fact-slice .partners .partner-slide .partner-logo-link,
.socialjustice-page .partner-slice .partners .partner-slide .partner-logo-link,
.socialjustice-page .video-thumbs-slice .partners .partner-slide .partner-logo-link {
  width: 150px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.michaelthompson-page .cta-slice .partners .partner-slide .partner-logo-link img,
.michaelthompson-page .fact-slice .partners .partner-slide .partner-logo-link img,
.michaelthompson-page .partner-slice .partners .partner-slide .partner-logo-link img,
.michaelthompson-page .video-thumbs-slice .partners .partner-slide .partner-logo-link img,
.socialjustice-page .cta-slice .partners .partner-slide .partner-logo-link img,
.socialjustice-page .fact-slice .partners .partner-slide .partner-logo-link img,
.socialjustice-page .partner-slice .partners .partner-slide .partner-logo-link img,
.socialjustice-page .video-thumbs-slice .partners .partner-slide .partner-logo-link img,
.expungement-events-page .cta-slice .partners .partner-slide .partner-logo-link img,
.expungement-events-page .fact-slice .partners .partner-slide .partner-logo-link img,
.expungement-events-page .partner-slice .partners .partner-slide .partner-logo-link img,
.expungement-events-page .video-thumbs-slice .partners .partner-slide .partner-logo-link img,
.socialjustice-page .cta-slice .partners .partner-slide .partner-logo-link img,
.socialjustice-page .fact-slice .partners .partner-slide .partner-logo-link img,
.socialjustice-page .partner-slice .partners .partner-slide .partner-logo-link img,
.socialjustice-page .video-thumbs-slice .partners .partner-slide .partner-logo-link img {
  width: 100%;
  height: auto;
}

.michaelthompson-page .cta-slice .partners .partner-slide .partner-content,
.michaelthompson-page .fact-slice .partners .partner-slide .partner-content,
.michaelthompson-page .partner-slice .partners .partner-slide .partner-content,
.michaelthompson-page .video-thumbs-slice .partners .partner-slide .partner-content,
.socialjustice-page .cta-slice .partners .partner-slide .partner-content,
.socialjustice-page .fact-slice .partners .partner-slide .partner-content,
.socialjustice-page .partner-slice .partners .partner-slide .partner-content,
.socialjustice-page .video-thumbs-slice .partners .partner-slide .partner-content,
.expungement-events-page .cta-slice .partners .partner-slide .partner-content,
.expungement-events-page .fact-slice .partners .partner-slide .partner-content,
.expungement-events-page .partner-slice .partners .partner-slide .partner-content,
.expungement-events-page .video-thumbs-slice .partners .partner-slide .partner-content,
.socialjustice-page .cta-slice .partners .partner-slide .partner-content,
.socialjustice-page .fact-slice .partners .partner-slide .partner-content,
.socialjustice-page .partner-slice .partners .partner-slide .partner-content,
.socialjustice-page .video-thumbs-slice .partners .partner-slide .partner-content {
  text-align: center;
  padding: 0px 50px;
}

.michaelthompson-page .cta-slice .partners .partner-slide .partner-content a,
.michaelthompson-page .fact-slice .partners .partner-slide .partner-content a,
.michaelthompson-page .partner-slice .partners .partner-slide .partner-content a,
.michaelthompson-page .video-thumbs-slice .partners .partner-slide .partner-content a,
.socialjustice-page .cta-slice .partners .partner-slide .partner-content a,
.socialjustice-page .fact-slice .partners .partner-slide .partner-content a,
.socialjustice-page .partner-slice .partners .partner-slide .partner-content a,
.socialjustice-page .video-thumbs-slice .partners .partner-slide .partner-content a,
.expungement-events-page .cta-slice .partners .partner-slide .partner-content a,
.expungement-events-page .fact-slice .partners .partner-slide .partner-content a,
.expungement-events-page .partner-slice .partners .partner-slide .partner-content a,
.expungement-events-page .video-thumbs-slice .partners .partner-slide .partner-content a,
.socialjustice-page .cta-slice .partners .partner-slide .partner-content a,
.socialjustice-page .fact-slice .partners .partner-slide .partner-content a,
.socialjustice-page .partner-slice .partners .partner-slide .partner-content a,
.socialjustice-page .video-thumbs-slice .partners .partner-slide .partner-content a {
  color: #f4c01a;
}

.michaelthompson-page .cta-slice .text-content-bottom,
.michaelthompson-page .fact-slice .text-content-bottom,
.michaelthompson-page .partner-slice .text-content-bottom,
.michaelthompson-page .video-thumbs-slice .text-content-bottom,
.socialjustice-page .cta-slice .text-content-bottom,
.socialjustice-page .fact-slice .text-content-bottom,
.socialjustice-page .partner-slice .text-content-bottom,
.socialjustice-page .video-thumbs-slice .text-content-bottom,
.expungement-events-page .cta-slice .text-content-bottom,
.expungement-events-page .fact-slice .text-content-bottom,
.expungement-events-page .partner-slice .text-content-bottom,
.expungement-events-page .video-thumbs-slice .text-content-bottom,
.socialjustice-page .cta-slice .text-content-bottom,
.socialjustice-page .fact-slice .text-content-bottom,
.socialjustice-page .partner-slice .text-content-bottom,
.socialjustice-page .video-thumbs-slice .text-content-bottom {
  text-align: center;
  margin-top: 30px;
}

.michaelthompson-page .cta-slice .text-content-bottom a,
.michaelthompson-page .fact-slice .text-content-bottom a,
.michaelthompson-page .partner-slice .text-content-bottom a,
.michaelthompson-page .video-thumbs-slice .text-content-bottom a,
.socialjustice-page .cta-slice .text-content-bottom a,
.socialjustice-page .fact-slice .text-content-bottom a,
.socialjustice-page .partner-slice .text-content-bottom a,
.socialjustice-page .video-thumbs-slice .text-content-bottom a,
.expungement-events-page .cta-slice .text-content-bottom a,
.expungement-events-page .fact-slice .text-content-bottom a,
.expungement-events-page .partner-slice .text-content-bottom a,
.expungement-events-page .video-thumbs-slice .text-content-bottom a,
.socialjustice-page .cta-slice .text-content-bottom a,
.socialjustice-page .fact-slice .text-content-bottom a,
.socialjustice-page .partner-slice .text-content-bottom a,
.socialjustice-page .video-thumbs-slice .text-content-bottom a {
  color: #f4c01a;
}

.michaelthompson-page .cta-slice .button-container,
.michaelthompson-page .fact-slice .button-container,
.michaelthompson-page .partner-slice .button-container,
.michaelthompson-page .video-thumbs-slice .button-container,
.socialjustice-page .cta-slice .button-container,
.socialjustice-page .fact-slice .button-container,
.socialjustice-page .partner-slice .button-container,
.socialjustice-page .video-thumbs-slice .button-container,
.expungement-events-page .cta-slice .button-container,
.expungement-events-page .fact-slice .button-container,
.expungement-events-page .partner-slice .button-container,
.expungement-events-page .video-thumbs-slice .button-container,
.socialjustice-page .cta-slice .button-container,
.socialjustice-page .fact-slice .button-container,
.socialjustice-page .partner-slice .button-container,
.socialjustice-page .video-thumbs-slice .button-container {
  text-align: center;
}

.michaelthompson-page .fact-slice.section-padding,
.socialjustice-page .fact-slice.section-padding,
.expungement-events-page .fact-slice.section-padding,
.socialjustice-page .fact-slice.section-padding {
  padding: 50px 30px;
}

.michaelthompson-page .text-background-slice,
.socialjustice-page .text-background-slice,
.expungement-events-page .text-background-slice,
.socialjustice-page .text-background-slice {
  padding: 80px;
  padding-bottom: 300px;
  background-position: center bottom !important;
  background-size: 300px !important;
  background-repeat: no-repeat !important;
  margin: 0;
  height: auto;
}

.michaelthompson-page .text-background-slice .button-container span,
.socialjustice-page .text-background-slice .button-container span,
.expungement-events-page .text-background-slice .button-container span,
.socialjustice-page .text-background-slice .button-container span {
  font-size: 1.2rem;
}

@media only screen and (min-width: 900px) {
  .michaelthompson-page .text-background-slice .button-container span,
  .socialjustice-page .text-background-slice .button-container span,
  .expungement-events-page .text-background-slice .button-container span,
  .socialjustice-page .text-background-slice .button-container span {
    font-size: 1.5rem;
  }
}

.line-custom-animation {
  overflow: visible;
  clear: both;
  position: relative;
  z-index: 3;
  display: none;
}

.line-custom-animation .wrap {
  -webkit-clip-path: circle(0% at 0% 0%);
          clip-path: circle(0% at 0% 0%);
  -webkit-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}

.line-custom-animation .wrap.right {
  -webkit-clip-path: circle(0% at 100% 0%);
          clip-path: circle(0% at 100% 0%);
}

.custom-animation {
  overflow: hidden;
}

.custom-animation .image-content {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 0;
  -webkit-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}

.custom-animation .text-content h2,
.custom-animation .text-content h4,
.custom-animation .text-content h6 {
  opacity: 0;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.custom-animation .text-content p {
  opacity: 0;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.custom-animation .text-content a .logos {
  opacity: 0;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.custom-animation.animation-active .image-content {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.custom-animation.animation-active .text-content h2,
.custom-animation.animation-active .text-content p,
.custom-animation.animation-active .text-content h4,
.custom-animation.animation-active .text-content h6 {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.custom-animation.animation-active a .logos {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.custom-animation.animation-active h2:before {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.custom-animation.scrolled-to-bottom .line-custom-animation .wrap {
  -webkit-clip-path: circle(140.9% at 0 0);
          clip-path: circle(140.9% at 0 0);
}

.custom-animation.scrolled-to-bottom .line-custom-animation .wrap.right {
  -webkit-clip-path: circle(140.7% at 100% 0);
          clip-path: circle(140.7% at 100% 0);
}

.custom-animation span.orange-color {
  color: white;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.custom-animation span.orange-background {
  background: transparent;
  position: relative;
  z-index: 2;
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  display: block;
}

.custom-animation span.orange-background:before {
  content: '';
  width: 140%;
  height: 100%;
  background: transparent;
  position: absolute;
  left: -40%;
  top: 0;
  z-index: -2;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}

.custom-animation.animation-active span.orange-color {
  color: #f4c01a;
}

.custom-animation.animation-active span.orange-background,
.custom-animation.animation-active .orange-background {
  position: relative;
  background: #f4c01a;
}

.custom-animation.animation-active span.orange-background:before,
.custom-animation.animation-active .orange-background:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #f4c01a;
}

@media only screen and (min-width: 900px) {
  .line-custom-animation {
    display: block;
  }
  .section-2 h2 {
    position: relative;
  }
  .section-2 h2:before {
    content: '';
    background: #f4c01a;
    width: 2000px;
    height: 8px;
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    margin-left: 0;
  }
  .michaelthompson-page .cta-slice .text-content .button-container a button,
  .michaelthompson-page .fact-slice .text-content .button-container a button,
  .michaelthompson-page .partner-slice .text-content .button-container a button,
  .socialjustice-page .cta-slice .text-content .button-container a button,
  .socialjustice-page .fact-slice .text-content .button-container a button,
  .socialjustice-page .partner-slice .text-content .button-container a button {
    width: 30%;
  }
  .michaelthompson-page .cta-slice .partners,
  .michaelthompson-page .fact-slice .partners,
  .michaelthompson-page .partner-slice .partners,
  .socialjustice-page .cta-slice .partners,
  .socialjustice-page .fact-slice .partners,
  .socialjustice-page .partner-slice .partners {
    margin-top: 120px;
  }
  .michaelthompson-page .cta-slice .partners .partner-slide,
  .michaelthompson-page .fact-slice .partners .partner-slide,
  .michaelthompson-page .partner-slice .partners .partner-slide,
  .socialjustice-page .cta-slice .partners .partner-slide,
  .socialjustice-page .fact-slice .partners .partner-slide,
  .socialjustice-page .partner-slice .partners .partner-slide {
    margin-bottom: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .michaelthompson-page .cta-slice .partners .partner-slide:last-of-type,
  .michaelthompson-page .fact-slice .partners .partner-slide:last-of-type,
  .michaelthompson-page .partner-slice .partners .partner-slide:last-of-type,
  .socialjustice-page .cta-slice .partners .partner-slide:last-of-type,
  .socialjustice-page .fact-slice .partners .partner-slide:last-of-type,
  .socialjustice-page .partner-slice .partners .partner-slide:last-of-type {
    margin-bottom: 0;
  }
  .michaelthompson-page .cta-slice .partners .partner-slide:nth-of-type(even) .partner-logo-link,
  .michaelthompson-page .fact-slice .partners .partner-slide:nth-of-type(even) .partner-logo-link,
  .michaelthompson-page .partner-slice .partners .partner-slide:nth-of-type(even) .partner-logo-link,
  .socialjustice-page .cta-slice .partners .partner-slide:nth-of-type(even) .partner-logo-link,
  .socialjustice-page .fact-slice .partners .partner-slide:nth-of-type(even) .partner-logo-link,
  .socialjustice-page .partner-slice .partners .partner-slide:nth-of-type(even) .partner-logo-link {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin: 0;
    margin-left: 80px;
  }
  .michaelthompson-page .cta-slice .partners .partner-slide .partner-logo-link,
  .michaelthompson-page .fact-slice .partners .partner-slide .partner-logo-link,
  .michaelthompson-page .partner-slice .partners .partner-slide .partner-logo-link,
  .socialjustice-page .cta-slice .partners .partner-slide .partner-logo-link,
  .socialjustice-page .fact-slice .partners .partner-slide .partner-logo-link,
  .socialjustice-page .partner-slice .partners .partner-slide .partner-logo-link {
    min-width: 200px;
    margin-right: 80px;
    display: block;
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .michaelthompson-page .cta-slice .partners .partner-slide .partner-logo-link img,
  .michaelthompson-page .fact-slice .partners .partner-slide .partner-logo-link img,
  .michaelthompson-page .partner-slice .partners .partner-slide .partner-logo-link img,
  .socialjustice-page .cta-slice .partners .partner-slide .partner-logo-link img,
  .socialjustice-page .fact-slice .partners .partner-slide .partner-logo-link img,
  .socialjustice-page .partner-slice .partners .partner-slide .partner-logo-link img {
    width: 100%;
    height: auto;
  }
  .michaelthompson-page .cta-slice .partners .partner-slide .partner-content,
  .michaelthompson-page .fact-slice .partners .partner-slide .partner-content,
  .michaelthompson-page .partner-slice .partners .partner-slide .partner-content,
  .socialjustice-page .cta-slice .partners .partner-slide .partner-content,
  .socialjustice-page .fact-slice .partners .partner-slide .partner-content,
  .socialjustice-page .partner-slice .partners .partner-slide .partner-content {
    text-align: left;
    padding: 0px;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .michaelthompson-page .special-image-content,
  .socialjustice-page .special-image-content {
    position: relative;
  }
  .michaelthompson-page .special-image-content.special-image-hero,
  .socialjustice-page .special-image-content.special-image-hero {
    padding: 100px 0;
  }
  .michaelthompson-page .special-image-content .section-padding,
  .socialjustice-page .special-image-content .section-padding {
    padding: 100px 60px;
  }
  .michaelthompson-page .special-image-content .text-content,
  .socialjustice-page .special-image-content .text-content {
    width: 55%;
    z-index: 2;
    position: relative;
    text-align: left;
  }
  .michaelthompson-page .special-image-content .text-content.align-right,
  .socialjustice-page .special-image-content .text-content.align-right {
    float: right;
  }
  .michaelthompson-page .special-image-content .image-content,
  .socialjustice-page .special-image-content .image-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .michaelthompson-page .special-image-content .image-content img,
  .socialjustice-page .special-image-content .image-content img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .michaelthompson-page .section-5 .section-padding,
  .michaelthompson-page .section-6 .section-padding,
  .michaelthompson-page .section-7 .section-padding,
  .socialjustice-page .section-5 .section-padding,
  .socialjustice-page .section-6 .section-padding,
  .socialjustice-page .section-7 .section-padding {
    padding: 60px !important;
  }
}

@media only screen and (min-width: 768px) {
  .michaelthompson-page .text-background-slice,
  .socialjustice-page .text-background-slice {
    padding-bottom: 80px;
    background-position: center right !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }
  .michaelthompson-page .text-background-slice .text-content,
  .socialjustice-page .text-background-slice .text-content {
    text-align: left;
  }
  .michaelthompson-page .special-image-content .text-content h2.orange-background:before,
  .socialjustice-page .special-image-content .text-content h2.orange-background:before {
    width: 100%;
    left: -50%;
  }
}

@media only screen and (max-width: 768px) {
  .michaelthompson-page .hero-section,
  .socialjustice-page .hero-section {
    height: calc(100vh - 80px);
  }
  .michaelthompson-page .hero-section .wrapper,
  .socialjustice-page .hero-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .michaelthompson-page .hero-section .wrapper .text-content,
  .socialjustice-page .hero-section .wrapper .text-content {
    margin-top: auto;
    text-align: center;
    height: auto;
  }
  .michaelthompson-page .special-image-content,
  .socialjustice-page .special-image-content {
    padding-top: 60px;
  }
  .custom-animation.section-3 span.orange-color {
    color: white;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }
  .custom-animation.section-3 span.orange-background {
    width: 100%;
    display: block;
    padding: 10px 0px;
  }
  .custom-animation.section-3 span.orange-background:before {
    width: calc(100% + 40px);
    left: -20px;
  }
  .custom-animation.section-3.animation-active span.orange-color {
    color: #f4c01a;
  }
}

.video-thumbs-slice .videos-list {
  margin-top: 40px;
}

.video-thumbs-slice .videos-list .MuiGrid-item .video img {
  width: 100%;
  height: auto;
  margin-bottom: 27px;
}

.youtube-video-embed .container {
  margin: auto;
  max-width: 768px;
}

@media only screen and (min-width: 768px) {
  .youtube-video-embed .container {
    padding: 0 20px 64px;
  }
}

.youtube-video-embed .container .copy {
  padding: 50px 20px 25px;
}

.youtube-video-embed .container .video-embed-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.youtube-video-embed .container .video-embed-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.dark-flower {
  position: relative;
  background: #001f3b;
}

.dark-flower:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: url("../imagesV2/backgrounds&Textures/flower-background.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.dark-flower.background-repeat:after {
  background: url("../imagesV2/backgrounds&Textures/flower-background.jpg") repeat;
}

.dark-blue-splatter {
  background-color: #001f3b;
  position: relative;
}

.dark-blue-splatter:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: url("../imagesV2/backgrounds&Textures/blue-dots-background-mobile.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: 0 -100px;
}

.background-wrapper {
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 900px) {
  .dark-blue-splatter:after {
    background: url("../imagesV2/backgrounds&Textures/blue-dots-background-desktop.png") no-repeat;
    background-size: cover;
    background-position: 0% 0%;
  }
}

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: inherit;
  overflow: hidden;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.hero-overlay:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: url("../imagesV2/backgrounds&Textures/overlay-mobile.png") no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 900px) {
  .hero-overlay:after {
    background: url("../imagesV2/backgrounds&Textures/overlay-desktop.png") no-repeat;
  }
}

.border,
.border span,
.lazy-img-border span {
  border: 1px solid #b9e0f7;
}

.border-bottom {
  border-bottom: 1px solid #b9e0f7;
}

.llr-info .llr-gold-stream-content {
  position: absolute;
  top: -51px;
  left: 0;
  height: auto;
  width: auto;
  z-index: 2;
}

@media only screen and (min-width: 400px) {
  .llr-info .llr-gold-stream-content {
    top: -59px;
  }
}

@media only screen and (min-width: 580px) {
  .llr-info .llr-gold-stream-content {
    top: -81px;
  }
}

@media only screen and (min-width: 680px) {
  .llr-info .llr-gold-stream-content {
    top: -100px;
  }
}

@media only screen and (min-width: 847px) {
  .llr-info .llr-gold-stream-content {
    top: -120px;
  }
}

@media only screen and (min-width: 1016px) {
  .llr-info .llr-gold-stream-content {
    top: -150px;
  }
}

@media only screen and (min-width: 1235px) {
  .llr-info .llr-gold-stream-content {
    top: -170px;
  }
}

@media only screen and (min-width: 1425px) {
  .llr-info .llr-gold-stream-content {
    top: -210px;
  }
}

@media only screen and (min-width: 1758px) {
  .llr-info .llr-gold-stream-content {
    top: -250px;
  }
}

@media only screen and (min-width: 2046px) {
  .llr-info .llr-gold-stream-content {
    top: -300px;
  }
}

.second-content {
  padding: 80px 35px;
}

@media only screen and (min-width: 590px) {
  .second-content {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 980px) {
  .second-content {
    padding-bottom: 170px;
  }
}

@media only screen and (min-width: 1235px) {
  .second-content {
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 1441px) {
  .second-content {
    padding-bottom: 240px;
  }
}

@media only screen and (min-width: 1848px) {
  .second-content {
    padding-bottom: 330px;
  }
}

.llr-content-padding {
  padding: 80px 35px;
}

@media only screen and (min-width: 520px) {
  .llr-content-padding {
    padding-top: 140px;
  }
}

@media only screen and (min-width: 834px) {
  .llr-content-padding {
    padding-top: 180px;
  }
}

@media only screen and (min-width: 982px) {
  .llr-content-padding {
    padding-top: 220px;
  }
}

@media only screen and (min-width: 1235px) {
  .llr-content-padding {
    padding-top: 260px;
  }
}

@media only screen and (min-width: 1441px) {
  .llr-content-padding {
    padding-top: 300px;
  }
}

@media only screen and (min-width: 1441px) {
  .llr-content-padding {
    padding-top: 350px;
  }
}

@media only screen and (min-width: 2108px) {
  .llr-content-padding {
    padding-top: 450px;
  }
}

.llr-hero-padding {
  padding: 80px 35px 40px;
}

@media only screen and (min-width: 500px) {
  .llr-hero-padding {
    padding: 120px 35px 40px;
  }
}

@media only screen and (min-width: 900px) {
  .llr-hero-padding {
    padding: 160px 35px 40px;
  }
}

@media only screen and (min-width: 1130px) {
  .llr-hero-padding {
    padding: 250px 50px 40px;
  }
}

@media only screen and (min-width: 1651px) {
  .llr-hero-padding {
    padding: 350px 50px 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .icons-wrapper {
    padding-top: 250px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1651px) {
  .icons-wrapper {
    padding-top: 350px;
  }
}

@media only screen and (min-width: 1392px) {
  .llr-hero {
    min-height: 1100px !important;
  }
}

.llr-hero .llr-gold-stream-hero {
  position: absolute;
  top: 657px;
  right: 0;
  z-index: 3;
  width: auto;
  height: auto;
}

@media only screen and (min-width: 450px) {
  .llr-hero .llr-gold-stream-hero {
    top: 643px;
  }
}

@media only screen and (min-width: 582px) {
  .llr-hero .llr-gold-stream-hero {
    top: 626px;
  }
}

@media only screen and (min-width: 700px) {
  .llr-hero .llr-gold-stream-hero {
    top: 626px;
  }
}

@media only screen and (min-width: 800px) {
  .llr-hero .llr-gold-stream-hero {
    top: 612px;
  }
}

@media only screen and (min-width: 900px) {
  .llr-hero .llr-gold-stream-hero {
    top: 791px;
  }
}

@media only screen and (min-width: 1150px) {
  .llr-hero .llr-gold-stream-hero {
    top: 778px;
  }
}

@media only screen and (min-width: 1278px) {
  .llr-hero .llr-gold-stream-hero {
    top: 765px;
  }
}

@media only screen and (min-width: 1392px) {
  .llr-hero .llr-gold-stream-hero {
    top: 939px;
  }
}

@media only screen and (min-width: 1651px) {
  .llr-hero .llr-gold-stream-hero {
    top: 939px;
  }
}

@media only screen and (min-width: 1683px) {
  .llr-hero .llr-gold-stream-hero {
    top: 903px;
  }
}

@media only screen and (min-width: 2034px) {
  .llr-hero .llr-gold-stream-hero {
    top: 869px;
  }
}

.scale {
  overflow: hidden;
}

.scale img {
  width: 100%;
  -webkit-transition: all 800ms ease-in-out;
  transition: all 800ms ease-in-out;
}

.scale .blue-gradient {
  background: #b9e0f7;
  background: linear-gradient(120deg, #b9e0f7, #b9e0f7, #f8fcfe, #b9e0f7, #b9e0f7, #b9e0f7, #b9e0f7);
  background-size: 400% 400%;
  background-position: 100% 0;
  -webkit-transition: background-position 0.8s ease-in-out;
  transition: background-position 0.8s ease-in-out;
}

.scale:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.scale:hover .blue-gradient {
  background-position: 0 0;
}

.scale-text .MuiButton-label p,
.scale-text p {
  -webkit-transition: 200ms linear;
  transition: 200ms linear;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.scale-text:hover .MuiButton-label p,
.scale-text:hover p {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.menu-icon {
  -webkit-tap-highlight-color: transparent;
}

.menu-icon svg {
  width: 45px;
}

.menu-icon svg .animate-line {
  -webkit-transition: 200ms linear all;
  transition: 200ms linear all;
  -webkit-transform: translate(12px, -4px) scaleX(0.55);
          transform: translate(12px, -4px) scaleX(0.55);
}

.menu-icon.animate-menu svg .animate-line {
  -webkit-transform: translate(0, 0) scaleX(1);
          transform: translate(0, 0) scaleX(1);
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

@keyframes animate {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 170px;
  height: 170px;
  background: transparent;
  border: 3px solid #304758;
  border-radius: 50%;
  text-align: center;
  line-height: 170px;
  font-family: sans-serif;
  font-size: 2rem;
  color: #87c7f7;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #87c7f7;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.ring:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 170px;
  height: 170px;
  border: 3px solid transparent;
  border-top: 3px solid #87c7f7;
  border-right: 3px solid #87c7f7;
  border-radius: 50%;
  -webkit-animation: animateC 2s linear infinite;
          animation: animateC 2s linear infinite;
}

.ring span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-animation: animate 2s linear infinite;
          animation: animate 2s linear infinite;
}

.ring span:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #87c7f7;
  top: -6px;
  right: -8px;
  -webkit-box-shadow: 0 0 20px #87c7f7;
          box-shadow: 0 0 20px #87c7f7;
}

.ring.small {
  width: 70px;
  height: 70px;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: bold;
}

.ring.small:before {
  width: 70px;
  height: 70px;
}

.ring.small .text {
  position: relative;
  bottom: 50px;
}

.ring.small span:before {
  width: 8px;
  height: 8px;
  top: 0px;
  right: -6px;
}

@-webkit-keyframes animateC {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes animateC {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

.carousel .control-dots .dot {
  background: #87c7f7;
}

.info-carousel-padding {
  padding-bottom: 50px;
}

.info-carousel {
  position: relative;
}

.info-carousel .carousel-slider {
  margin: 0 auto;
  overflow: visible;
  position: relative;
}

.info-carousel .carousel-slider .control-arrow {
  background: url("../imagesV2/buttonStyles/right-arrow.svg") no-repeat !important;
  opacity: 1 !important;
  height: 25px;
}

.info-carousel .carousel-slider .control-arrow.control-next {
  top: 133px;
  left: 200px;
}

.info-carousel .carousel-slider .control-arrow.control-prev {
  top: 133px;
  left: -85px;
}

.info-carousel .slider-wrapper {
  overflow: visible;
}

.info-carousel .slide {
  pointer-events: none;
}

.info-carousel .slide .active-icon {
  display: none;
}

.info-carousel .slide .inactive-icon {
  display: block;
}

.info-carousel .slide .terpenes {
  display: none;
}

.info-carousel .slide .attributes-wrapper {
  display: none;
}

.info-carousel .slide.selected .active-icon {
  display: block;
}

.info-carousel .slide.selected .inactive-icon {
  display: none;
}

.info-carousel .slide.selected .terpenes {
  margin-top: 15px;
  position: relative;
  margin-left: -66px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
}

.info-carousel .slide.selected .terpenes figure {
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 372px) {
  .info-carousel .slide.selected .terpenes {
    position: static;
    margin: 15px 0 0 0;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

.info-carousel .slide.selected .attributes-wrapper {
  display: block;
  margin-top: 100px;
}

.info-carousel .control-dots {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 200%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 134px;
  width: auto;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -6%);
          transform: translate(-50%, -6%);
  left: 50%;
  height: 15px;
}

.info-carousel.terpene-info .control-dots {
  top: 134px;
  height: 15px;
  width: auto;
}

.info-carousel.terpene-info .control-dots .dot {
  margin: 0 4px;
}

.info-slide {
  margin-bottom: 50px;
}

.info-slide figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  width: 75px;
  height: 75px;
}

.info-slide figure .active-icon,
.info-slide figure .inactive-icon {
  height: inherit;
  width: inherit;
}

.info-slide figure.carousel-icon-style {
  height: 100px;
  width: 100px;
}

.info-slide figure.carousel-icon-style .active-icon {
  height: 100px;
  width: 100px;
}

.info-slide figure.carousel-icon-style .inactive-icon {
  height: 50px;
  width: 50px;
}

.info-slide .content {
  font-size: 2.8rem;
}

@media only screen and (min-width: 500px) {
  .info-slide .carousel-icon-style {
    height: 150px;
    width: 150px;
  }
  .info-slide .carousel-icon-style .active-icon {
    height: 150px;
    width: 150px;
  }
  .info-slide .carousel-icon-style .inactive-icon {
    height: 70px;
    width: 70px;
  }
}

@media only screen and (min-width: 768px) {
  .info-slide .carousel-icon-style {
    height: 170px;
    width: 170px;
  }
  .info-slide .carousel-icon-style .active-icon {
    height: 170px;
    width: 170px;
  }
  .info-slide .carousel-icon-style .inactive-icon {
    height: 100px;
    width: 100px;
  }
  .info-carousel .control-dots {
    top: 220px;
  }
}

.map-wrapper {
  margin-top: 10px;
  /* Track */
  /* Handle */
}

.map-wrapper ::-webkit-scrollbar {
  height: 5px;
  border-radius: 20px;
  scrollbar-width: thin;
}

.map-wrapper ::-webkit-scrollbar-track {
  background: rgba(185, 224, 247, 0.5);
  border-radius: 20px;
  height: 5px;
  margin: 0 20px;
}

.map-wrapper ::-webkit-scrollbar-thumb {
  background: #b9e0f7;
  border-radius: 20px;
}

.map-wrapper .interactive-map {
  height: 400px !important;
  width: 100% !important;
  position: relative !important;
  display: block;
}

.map-wrapper .info-window-content .store-name {
  font-size: 1.4rem;
  color: #001f3b;
  font-family: 'Poppins-Semibold';
  margin-bottom: 5px;
}

.map-wrapper .info-window-content .store-address {
  margin-bottom: 10px;
}

.map-wrapper .info-window-content .store-address,
.map-wrapper .info-window-content .usage-type {
  color: #001f3b;
  font-size: 1.2rem;
}

.map-wrapper .locations-list-wrapper {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.map-wrapper .locations-list-wrapper .locations-list-title {
  margin: 0 0 20px 0;
  padding: 0px 20px;
  color: #87c7f7;
}

.map-wrapper .locations-list-wrapper .pagination-nav {
  padding: 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: rgba(2, 6, 10, 0.7);
  font-family: 'Poppins-Semibold';
  color: #b9e0f7;
  text-transform: uppercase;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span {
  cursor: pointer;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span:nth-of-type(1) {
  margin-right: 10px;
}

.map-wrapper .locations-list-wrapper .info-message {
  background: rgba(2, 6, 10, 0.7);
  color: #b9e0f7;
  padding: 8px 20px;
  font-family: 'Poppins-Semibold';
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #87c7f7;
}

.map-wrapper .locations-list-wrapper .locations-search-form {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  margin-bottom: 40px;
}

.map-wrapper .locations-list-wrapper .locations-list {
  background: transparent;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  position: relative;
  margin-top: 10px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location {
  padding: 20px;
  overflow: auto;
  border-bottom: 1px solid #87c7f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 20px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location:last-of-type {
  border-bottom: 0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location.active .store-information {
  outline: 1px solid #87c7f7;
  padding: 10px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information {
  margin-right: 25px;
  width: 100%;
  color: #b9e0f7;
  text-align: left;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-name {
  margin-bottom: 5px;
  color: #b9e0f7;
  text-transform: capitalize;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-address {
  font-size: 1.2rem;
  line-height: 1.2;
  margin-bottom: 10px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .distance {
  font-size: 1.2rem;
  line-height: 1.2;
  margin: 20px 0 10px;
  color: #87c7f7;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .usage-type {
  font-size: 1.2rem;
  font-weight: 900;
  margin-bottom: 10px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button {
  font-family: 'Poppins-Semibold';
  color: #87c7f7;
  font-size: 10px;
  text-decoration: none;
  font-weight: 900;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  text-transform: uppercase;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button img {
  width: 20px;
  height: 20px;
  display: block;
}

@media only screen and (min-width: 900px) {
  .map-wrapper {
    overflow: auto;
  }
  .map-wrapper .back-to-top {
    display: none;
  }
  .map-wrapper .section-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    max-width: 1300px;
    margin: auto;
  }
  .map-wrapper .info-window-content {
    text-align: left;
  }
  .map-wrapper .info-window-content .store-name {
    font-size: 1.6rem;
  }
  .map-wrapper .info-window-content .store-address {
    font-size: 1.4rem;
  }
  .map-wrapper .store-information .store-name {
    font-size: 1.6rem;
  }
  .map-wrapper .store-information .store-address {
    margin-top: 10px;
  }
  .map-wrapper .store-information .store-address,
  .map-wrapper .store-information .distance {
    font-size: 1.4rem !important;
  }
  .map-wrapper .map-list-wrapper {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 1300px;
  }
  .map-wrapper .map-wrapper {
    height: calc(100vh - 100px) !important;
    width: 60%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-top: 0;
    margin-left: 30px;
  }
  .map-wrapper .map-wrapper .interactive-map {
    height: calc(100vh - 100px) !important;
    width: 100% !important;
  }
  .map-wrapper .stores-wrapper {
    height: calc(100vh - 100px) !important;
  }
  .map-wrapper .locations-list-wrapper .locations-list {
    height: calc(100vh - 180px) !important;
    overflow-y: scroll;
    width: 500px;
  }
  .map-wrapper .locations-list-wrapper .locations-list-title {
    margin: 0 0 30px 0;
  }
  .map-wrapper .locations-list-wrapper .info-message,
  .map-wrapper .locations-list-wrapper .pagination-nav {
    font-size: 1.2rem;
  }
  .map-wrapper .locations-list-wrapper .pagination-nav {
    margin-bottom: 15px;
  }
}

#age-gate {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#age-gate .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

#age-gate .age-gate-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  z-index: 3;
  max-height: 600px;
  -ms-flex-item-align: center;
      align-self: center;
  text-align: center;
  position: relative;
}

#age-gate .age-gate-content a {
  color: inherit;
}

#age-gate .age-gate-content .spanish-toggle {
  position: absolute;
  top: 0;
  right: 0;
  color: #b9e0f7;
  margin-top: 10px;
  z-index: 3;
}

#age-gate .age-gate-content .spanish-toggle .switch-text {
  font-size: 1.4rem;
  display: inline-block;
  position: relative;
  right: 15px;
  top: 2px;
  margin-left: 5px;
  font-family: sans-serif;
}

#age-gate .age-gate-content .logo {
  width: 100%;
  height: auto;
  max-width: 400px;
  margin: 0 auto;
}

#age-gate .age-gate-content .logo img {
  width: 100%;
}

#age-gate .age-gate-content .content-section {
  color: #b9e0f7;
}

#age-gate .age-gate-content h2 {
  padding-top: 40px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto 25px;
  line-height: 1.1;
}

#age-gate .age-gate-content p {
  margin: 20px 0px;
}

#age-gate .age-gate-content form {
  margin-bottom: 20px;
  line-height: 1;
  text-align: center !important;
  display: block;
}

#age-gate .age-gate-content form span {
  font-size: 1.4rem;
  color: #b9e0f7;
}

#age-gate .age-gate-content form .error-message {
  display: inline-block;
  width: auto;
  padding: 10px 15px;
  background: #c41e3a;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1.4rem;
  margin-top: 0;
}

#age-gate .age-gate-content .legal-text p {
  font-size: 15px;
}

#age-gate .age-gate-content .legal-text a {
  font-weight: 900;
}

@media only screen and (min-width: 768px) {
  #age-gate .age-gate-content {
    padding: 50px;
  }
}

.faq-popover .MuiPopover-paper {
  bottom: 20px;
  right: 20px;
}

.shop-page-dialog .MuiPaper-rounded {
  border-radius: 0px !important;
}

.shop-page-dialog .MuiDialog-paperWidthSm {
  max-width: 1034px !important;
}

.dialog-wrapper {
  position: relative;
  background: #001f3b;
  text-align: center;
}

.dialog-wrapper .content-img-wrapper {
  padding: 17px 35px;
}

.dialog-wrapper .close {
  position: absolute;
  top: 10px;
  right: 20px;
}

.dialog-wrapper .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}

.dialog-wrapper .button-container .sunny-logo,
.dialog-wrapper .button-container .jane-logo {
  margin: 0 auto 25px;
  display: block;
}

.dialog-wrapper .button-container .sunny-logo {
  width: 250px;
}

.dialog-wrapper .button-container .jane-logo {
  width: 150px;
}

.dialog-wrapper .button-container .shop-sunny {
  padding-bottom: 35px;
  margin-bottom: 32px;
}

.dialog-wrapper .button-container a button {
  font-size: 12px;
  border-radius: 20px;
  height: 30px;
}

.dialog-wrapper .logo-container {
  margin-bottom: 20px;
}

.dialog-wrapper .logo-container svg {
  max-width: 150px;
}

.dialog-wrapper .content {
  margin: 25px auto 10px;
  max-width: 450px;
}

.dialog-wrapper .content p {
  font-size: 14px;
}

.dialog-wrapper .disclaimer-text p {
  font-size: 12px;
  line-height: 14px;
  max-width: 500px;
  margin: 35px auto 0 auto;
}

@media only screen and (min-width: 900px) {
  .dialog-wrapper .content-img-wrapper {
    padding: 30px 35px;
  }
  .dialog-wrapper .button-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 30px;
    gap: 30px !important;
  }
  .dialog-wrapper .button-container .sunny-logo,
  .dialog-wrapper .button-container .jane-logo {
    display: block;
  }
  .dialog-wrapper .button-container .sunny-logo {
    width: 400px;
    margin-bottom: 35px;
  }
  .dialog-wrapper .button-container .jane-logo {
    width: 247px;
  }
  .dialog-wrapper .button-container .shop-sunny {
    padding-bottom: 0;
    margin-bottom: 0;
    padding: 15px 35px 0 35px;
    position: relative;
    top: 34px;
  }
  .dialog-wrapper .button-container .shop-jane {
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container a button {
    font-size: 16px;
    border-radius: 20px;
    height: 35px;
  }
  .dialog-wrapper .logo-container {
    margin-bottom: 20px;
  }
  .dialog-wrapper .logo-container svg {
    max-width: 150px;
  }
  .dialog-wrapper .content {
    margin-bottom: 50px;
  }
  .dialog-wrapper .content p {
    font-size: 18px;
  }
  .dialog-wrapper .disclaimer-text {
    margin-top: 50px;
  }
}

.react-horizontal-scrolling-menu--scroll-container {
  padding: 0 0 50px 0px;
}

.react-horizontal-scrolling-menu--wrapper,
.react-horizontal-scrolling-menu--inner-wrapper {
  /* Track */
  /* Handle */
}

.react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar,
.react-horizontal-scrolling-menu--inner-wrapper ::-webkit-scrollbar {
  height: 8px;
  border-radius: 20px;
}

.react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar-track,
.react-horizontal-scrolling-menu--inner-wrapper ::-webkit-scrollbar-track {
  background: rgba(185, 224, 247, 0.5);
  border-radius: 20px;
  height: 10px;
  margin: 0 20px;
}

.react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar-thumb,
.react-horizontal-scrolling-menu--inner-wrapper ::-webkit-scrollbar-thumb {
  background: #b9e0f7;
  border-radius: 20px;
}

@media only screen and (min-width: 900px) {
  .react-horizontal-scrolling-menu--wrapper,
  .react-horizontal-scrolling-menu--inner-wrapper {
    /* Track */
  }
  .react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar-track,
  .react-horizontal-scrolling-menu--inner-wrapper ::-webkit-scrollbar-track {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 900px) and (min-width: 1500px) {
  .react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar-track,
  .react-horizontal-scrolling-menu--inner-wrapper ::-webkit-scrollbar-track {
    margin: 0 400px;
  }
}

@media only screen and (min-width: 900px) and (min-width: 1800px) {
  .react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar-track,
  .react-horizontal-scrolling-menu--inner-wrapper ::-webkit-scrollbar-track {
    margin: 0 600px;
  }
}

footer {
  padding: 24px 35px;
}

footer a {
  display: inline-block;
  text-decoration: none;
  font-family: 'Poppins-Regular';
}

footer a:hover {
  text-decoration: underline;
}

footer .social-link,
footer .social-link img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

footer .footer-section p {
  margin: 0;
}

footer .footer-section a {
  margin-bottom: 10px;
  color: #87c7f7;
}

footer .link-grid {
  max-width: 450px;
  display: -ms-grid;
  display: grid;
      grid-template-areas: 'a b' 'a c' 'a .' 'a .';
  gap: 30px 25px;
}

footer .link-grid .footer-section:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 4;
  -ms-grid-column: 1;
  grid-area: a;
}

footer .link-grid .footer-section:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: b;
}

footer .link-grid .footer-section:nth-child(3) {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: c;
}

@media only screen and (min-width: 800px) {
  footer {
    padding: 64px;
  }
  footer .link-grid {
    max-width: 400px;
  }
}

@media only screen and (min-width: 1000px) {
  footer {
    margin-left: 20%;
  }
}
