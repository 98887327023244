*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-size: 10px;
  background: $blackish;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  height: 100%;
  width: 100%;
}

.MuiSelect-outlined.MuiSelect-outlined {
  color: $lightBlue !important;
  font-size: 1.8rem !important;
  text-align: center;
  font-family: 'Poppins-Semibold';
  text-transform: uppercase;
}

.slice-single {
  background: $blackish;
}

.uppercase {
  text-transform: uppercase;
}

.contain-img {
  margin: 0;
  padding: 0;
  display: block;
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    object-fit: contain;
    margin: 0;
    padding: 0;
  }
}

.fullscreen-background {
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.cover-img {
  margin: 0;
  padding: 0;
  height: 100%;
  span {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }
}

.display-block {
  display: block !important;
}

.dark-blue {
  color: $darkBlue;
}

.light-blue {
  color: $lightBlue;
  a {
    color: inherit;
  }
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.resp-mobile-bottom {
  margin-bottom: 20px;
}

.overflow-hidden {
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.no-underline {
  text-decoration: none;
}

.text-center,
.center {
  text-align: center;
}

.text-left,
.left {
  text-align: left;
}

.text-right,
.right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.mx-auto {
  margin: auto;
}

.relative {
  position: relative;
}

.padding {
  padding: 80px 35px;
  @media only screen and (min-width: 900px) {
    padding: 160px 50px;
  }
}

.padding-less {
  padding: 80px 20px;
  @media only screen and (min-width: 900px) {
    padding: 160px 50px;
  }
}

.padding-tb {
  padding: 80px 0;
  @media only screen and (min-width: 900px) {
    padding: 160px 0;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.height-100 {
  height: 100%;
}

.align-items-center {
  align-items: center;
}

.max-width {
  max-width: 1400px;
  margin: auto;
}

.scroller {
  [data-index='0'] {
    margin-left: 20px;
  }
}

.lighter-blue {
  color: $lighterBlue;
}

figure {
  margin: 0;
  padding: 0;
}

.resp-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 220px));
  justify-content: center;
  &.gap {
    gap: 10px 0px;
  }
}

.height-auto {
  height: auto;
}

.loading-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: $darkBlue;
  z-index: 1000000000;
}

@media only screen and (min-width: 900px) {
  .resp-mobile-bottom {
    margin-bottom: 57px;
  }

  .scroller.results-scroller {
    justify-content: center;
  }
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}
