.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: inherit;
  overflow: hidden;
  video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
