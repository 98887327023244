@import 'mixins';

button p {
  margin: 0;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  &.smaller-margin {
    margin-top: 16px;
  }
  &.no-margin {
    margin-top: 0;
  }
  &.stacked {
    flex-direction: column;
    gap: 18px;
  }

  &.gap {
    gap: 18px;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  &.center {
    justify-content: center;
  }
  &.center-left {
    justify-content: center;
  }
  &.left {
    justify-content: flex-start;
  }
  &.select-fields {
    width: 150px;
  }
}

.button-container {
  .MuiButtonBase-root {
    &.MuiButton-contained,
    &.MuiButton-outlined {
      @include baseButtonStyle;

      .MuiButton-label {
        display: inline;
      }
    }

    &.MuiButton-contained {
      @include containedButton;
    }

    &.MuiButton-outlined {
      @include outlinedButton;
    }
  }

  &.llr-button-container {
    .MuiButtonBase-root {
      // &.MuiButton-contained {
      //   @include baseButtonStyle;
      // }

      &.MuiButton-contained {
        @include containedLLRButton;
      }
    }
  }
  // MUI Buttons

  // Simple button elements like randomize button / store locator search
  .outlined-button {
    cursor: pointer;
    @include baseButtonStyle;
    @include outlinedButton;
    font-family: Poppins-Semibold;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    width: 203px;
    height: 45px;
  }

  // Simple button elements
  .contained-button {
    cursor: pointer;
    @include baseButtonStyle;
    @include containedButton;
    font-family: Poppins-Semibold;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    width: 203px;
    height: 45px;
  }

  // Form Control Buttons

  .MuiFormControl-root,
  .MuiInputBase-root {
    @include baseButtonStyle;
  }
}

// Style for Logo Slice buttons
.outlined-button-style {
  display: block;
  position: relative;
  width: 204px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms linear;
  @include outlinedButton;
}

@media only screen and (min-width: 900px) {
  .button-container {
    gap: 30px;
    margin-top: 80px;
    &.smaller-margin {
      margin-top: 32px;
    }
    &.stacked {
      gap: 40px;
    }
    &.center-left {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .button-container {
    .MuiButtonBase-root {
      &.MuiButton-contained,
      &.MuiButton-outlined {
        @include baseButtonLargeScreens;
      }
    }

    // Simple button elements
    .outlined-button {
      @include baseButtonLargeScreens;
      &.small-button {
        width: 203px;
        height: 45px;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  // Style for Logo Slice buttons
  .outlined-button-style {
    width: 437px;
    height: 92px;
  }
  .button-container {
    &.select-fields {
      width: 392px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .button-container {
    flex-wrap: nowrap;
  }
}
