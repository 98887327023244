$cresco-orange: #f4c01a;
$cresco-orange-yellow: #f5b434;

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.text-content ul {
  padding-left: 40px;
  max-width: 1000px;
  margin: 1.5rem auto;
  li {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 1px;
    margin-bottom: 1rem;
    a {
      color: $cresco-orange;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 768px) {
  .section-half {
    padding: 100px 50px;
    .wrapper {
      flex-direction: row;
      .text-content,
      .img-content {
        width: 50%;
      }
      .text-content {
        order: 2;
        margin-bottom: 0;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 40px;
        box-sizing: border-box;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animation {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  &.yas {
    opacity: 1;
    .hero-section {
      .background-image,
      .background-video,
      .hero-carousel {
        transform: scale(1);
      }
      .text-content {
        opacity: 1;
        transform: translateY(0);
      }
      .product-slides-container {
        &:hover {
          img {
            transform: scale(0.9) !important;
          }
        }
        img {
          transform: translateX(0);
          // opacity: 1;
          &:nth-of-type(1) {
            transform: scale(0.9);
          }
          &:nth-of-type(3) {
            transform: scale(1.2);
          }
          &:nth-of-type(5) {
            transform: scale(0.9);
          }
          &:hover {
            transform: scale(1.2) !important;
          }
        }
      }
    }
  }
}

.text-section {
  background-size: cover !important;
  background-position: center center !important;
  .wrapper {
    * {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    p {
      margin: 20px 0px;
    }
    .image-wrapper {
      margin-top: 40px;
      img {
        max-width: 80%;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .text-section {
    padding: 114px 48px;
  }
}

.text-image-section {
  .wrapper {
    max-width: 1300px;
    .content-wrapper {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
    }
    .button-wrapper {
      margin-top: 24px;
    }
    .image-wrapper {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
  &.animate {
    .wrapper {
      .content-wrapper {
        transform: translateX(100px) scale(0.9);
        opacity: 0;
        transition: all 400ms ease-in;
      }
      .image-wrapper {
        transform: translateX(-100px) scale(0.9);
        opacity: 0;
        transition: all 400ms ease-in;
      }
      .lead-image {
        .content-wrapper {
          transform: translateX(-100px);
        }
        .image-wrapper {
          transform: translateX(100px);
        }
      }
    }
    &.animation-active {
      .wrapper {
        .content-wrapper,
        .image-wrapper {
          transform: translateX(0) scale(1);
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .text-image-section {
    .wrapper {
      .image-wrapper {
        min-width: 50%;
        max-width: 50%;
        margin-left: 0;
        margin-top: 0;
        max-width: none;
        img {
          margin: 40px 0px;
        }
      }
      &.full-width {
        max-width: none !important;
        .image-wrapper {
          margin-left: 0;
          margin-top: 0;
          max-width: none;
          align-self: stretch;
          img {
            margin: 0 !important;
          }
        }
        .content-wrapper {
          .content-wrapper-inner {
            max-width: 550px;
            width: 100%;
            float: right;
          }
        }
      }
      .content-wrapper {
        text-align: inherit;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .button-wrapper {
    .button {
      width: auto !important;
    }
  }
}

.section-padding {
  padding: 20px;
}

.section-padding-2 {
  padding: 30px;
}

@media only screen and (min-width: 768px) {
  .section-padding {
    padding: 40px;
  }
}

@media only screen and (min-width: 1279px) {
  .section-padding {
    padding: 60px;
  }
}

.animate {
  transform: translateY(100px) scale(0.9);
  transition: all 400ms ease-in;
  opacity: 0;
  &.animation-active {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

//Text Slice

.text-slice {
  .wrapper {
    .text-slice-logo {
      width: 100%;
      max-width: 45px;
      margin: 10px auto 50px;
      @media only screen and (min-width: 1000px) {
        margin: 50px auto;
      }
    }
    h3 {
      line-height: 1.2;
    }
    .icon {
      margin: 0 auto;
      margin-bottom: 20px;
      width: 47px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .text-content {
      max-width: 1000px;
      margin: 0 auto;
    }
    .buttons {
      text-align: center;
      margin-top: 20px;
    }
  }
}

.full-width-image {
  width: 100%;
  height: auto;
}

.carousel {
  .slide {
    background: none;
  }
}

.carousel .thumbs-wrapper {
  margin: 0;
  .control-arrow {
    display: none !important;
  }
  .thumbs {
    display: flex;
    justify-content: center;
    transform: translate3d(0px, 0px, 0px) !important;
    margin: 0;
    .thumb {
      margin-right: 5px;
      margin-top: 5px;
      border: 1px solid white;
      box-sizing: border-box;
      padding: 0;
      width: 100% !important;
      cursor: pointer;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        border: 1px solid $cresco-orange;
      }
      &.selected {
        border: 1px solid $cresco-orange;
      }
    }
  }
}

.control-arrow {
  &:before {
    display: none !important;
  }
  background: url('../imagesV2/icons/arrow.svg') no-repeat !important;
  background-size: contain !important;
  background-position: center center !important;
  width: 30px;
  &.control-prev {
    transform: rotate(180deg) !important;
  }
}

.questions-slice {
  .desktop-copy {
    display: none;
  }
  .questions-carousel {
    .slide {
      background: none;
      padding: 0px 20px;
      .image-content {
        margin-bottom: 20px;
        img {
          width: 80%;
          margin: 0 auto;
        }
      }
      .text-content {
        margin-bottom: 20px;
      }
    }
  }
}

.text-background-slice {
  background-size: cover !important;
  background-position: center center !important;
  box-sizing: border-box;
  height: calc(100% - 10px);
  min-height: 400px;
  display: flex;
  flex-direction: row;
  padding: 40px 20px;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &.align-bottom {
      justify-content: flex-end;
    }
    .foreground-image {
      width: 80%;
      margin: 0 auto;
    }
    .text-content {
      text-align: center;
    }
  }
}

@media only screen and (min-width: 768px) {
  .text-background-slice {
    .wrapper {
      flex-direction: row;
      min-height: none;
      align-items: center;
      .text-content {
        width: 50%;
      }
      &.align-right {
        justify-content: flex-end;
      }
    }
  }
}

.video-slice {
  video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

@media only screen and (min-width: 768px) {
  .slice-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .slice-single {
      width: 100%;
      // overflow: auto;
      &.half-width {
        width: 50%;
        .text-background-slice {
          box-sizing: border-box;
          padding: 50px 25px;
          .wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .text-content {
              max-width: none !important;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.column-section {
  .wrapper {
    .column-wrapper {
      .column {
        .column-image {
          max-width: 400px;
          margin: 0 auto;
          margin-bottom: 20px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .column-section {
    overflow: auto;
    .wrapper {
      .text-content {
        max-width: 800px;
        margin: 0 auto;
        margin-top: 40px;
      }
      .column-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 40px;
        .column {
          width: 100%;
          &.box-padding {
            padding-top: 0;
          }
        }
      }
    }
  }
}

.text-slider-slice {
  .wrapper {
    .mobile-image-container {
      width: 100%;
      height: 100%;
      order: 2;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .slider-container {
      .carousel {
        .slide {
          text-align: left;
          .box-padding {
            padding: 40px 60px;
          }
        }
        .control-arrow {
          filter: brightness(0) invert(1);
        }
        .control-dots {
          margin: 0px 60px;
          width: 100%;
          width: calc(100% - 120px);
          margin-bottom: 20px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .text-slider-slice {
    .wrapper {
      display: flex;
      flex-direction: row;
      .image-container {
        width: 30%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center right;
        }
      }
      .slider-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .carousel {
          .control-dots {
            // margin: 0;
          }
        }
      }
    }
  }
}

.michaelthompson-page,
.socialjustice-page,
.expungement-events-page,
.socialjustice-page {
  .section-padding {
    padding: 40px 20px;
  }
  .hero-section {
    margin-bottom: 10px;
    .text-content {
      max-width: 700px;
    }
    a:nth-of-type(1) {
      button {
        border: 2px solid $cresco-orange !important;
        color: white !important;
        background: $cresco-orange !important;
        &:hover {
          background: white !important;
          border: 2px solid white !important;
          color: pink;
        }
      }
    }
  }
  .special-image-content {
    &.special-image-hero {
      .section-padding {
        padding: 60px;
      }
    }

    &.special-image-yellow-background {
      .wrapper .text-content p,
      .wrapper .text-content h2 {
        color: $darkBlue !important;
      }
    }

    .text-content {
      text-align: center;
      box-sizing: border-box;
      span {
        display: inline;
      }
      p {
        font-size: 18px;
        margin-top: 40px;
      }
      a {
        color: $cresco-orange;
      }
      .logos {
        display: inline;
        width: 100%;
        max-width: 540px;
        margin-top: 40px;
      }
      h2 {
        &.orange-background {
          padding-top: 10px;
          &:before {
            width: 200%;
            left: -50%;
            z-index: -1;
          }
        }
      }
      h4 {
        text-transform: none !important;
        font-size: 30px;
      }
      h5 {
        font-size: 50px;
        line-height: 60px;
        text-transform: none;
        margin-top: 40px;
      }
      h6 {
        margin-top: 20px;
        text-transform: none;
        font-size: 20px;
      }
    }
    .image-content {
      img {
        width: 100%;
      }
    }
    .line {
      svg {
        display: none;
      }
    }
  }

  .cta-slice,
  .fact-slice,
  .partner-slice,
  .video-thumbs-slice {
    .wrapper {
      max-width: 1085px;
    }
    margin: 0;

    span.orange-color {
      color: $cresco-orange;
      display: inline;
    }
    &.section-padding {
      padding: 100px 60px;
    }
    .text-content {
      text-align: center;
      p {
        margin-top: 30px;
        a {
          color: $cresco-orange;
        }
      }
      h2 {
        position: relative;
        &:before {
          content: '';
          background: $cresco-orange;
          width: 170px;
          height: 8px;
          display: block;
          position: absolute;
          bottom: -10px;
          left: 50%;
          margin-left: -85px;
        }
      }
    }
    .fact-slider {
      background: $cresco-orange;
      margin-top: 30px;
      .slide {
        display: flex;
        justify-content: center;
      }
      .fact-slide {
        padding: 35px;
        p,
        a {
          color: $darkBlue !important;
        }
      }
    }
    .partners {
      margin-top: 80px;
      .control-arrow {
        filter: brightness(0) invert(1) !important;
      }
      .partner-slide {
        .partner-logo-link {
          width: 150px;
          display: block;
          margin: 0 auto;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .partner-content {
          text-align: center;
          padding: 0px 50px;
          a {
            color: $cresco-orange;
          }
        }
      }
    }
    .text-content-bottom {
      text-align: center;
      margin-top: 30px;
      a {
        color: $cresco-orange;
      }
    }
    .button-container {
      text-align: center;
    }
  }
  .fact-slice {
    &.section-padding {
      padding: 50px 30px;
    }
  }
  .text-background-slice {
    padding: 80px;
    padding-bottom: 300px;
    background-position: center bottom !important;
    background-size: 300px !important;
    background-repeat: no-repeat !important;
    margin: 0;
    height: auto;
    .button-container {
      span {
        font-size: 1.2rem;
        @media only screen and (min-width: 900px) {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.line-custom-animation {
  overflow: visible;
  clear: both;
  position: relative;
  z-index: 3;
  display: none;
  .wrap {
    clip-path: circle(0% at 0% 0%);
    transition: all 2s ease-in-out;
    &.right {
      clip-path: circle(0% at 100% 0%);
    }
  }
}

.custom-animation {
  overflow: hidden;
  .image-content {
    transform: scale(1.1);
    opacity: 0;
    transition: all 2s ease-in-out;
  }
  .text-content {
    h2,
    h4,
    h6 {
      opacity: 0;
      transition: all 1s ease-in-out;
      transform: translateY(-20px);
    }
    p {
      opacity: 0;
      transition: all 1s ease-in-out;
      transform: translateY(-20px);
      // transition-delay: 0.5s;
    }
    a {
      .logos {
        opacity: 0;
        transition: all 1s ease-in-out;
        transform: translateY(-20px);
        // transition-delay: 0.5s;
      }
    }
  }

  // &.section-2 {
  //   h2 {
  //     &:before {
  //       opacity: 0;
  //       transform: translateX(100%);
  //       transition: all 1s ease-in-out;
  //     }
  //   }
  // }
  &.animation-active {
    .image-content {
      opacity: 1;
      transform: scale(1);
    }
    .text-content {
      h2,
      p,
      h4,
      h6 {
        opacity: 1;
        transform: translateY(0);
      }
    }
    a {
      .logos {
        opacity: 1;
        transform: translateY(0);
      }
    }
    h2 {
      &:before {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  }
  &.scrolled-to-bottom {
    .line-custom-animation {
      .wrap {
        clip-path: circle(140.9% at 0 0);
        &.right {
          clip-path: circle(140.7% at 100% 0);
        }
      }
    }
  }

  // &.section-3 {
  span.orange-color {
    color: white;
    transition: all 1s ease-in-out;
    transition-delay: 1s;
  }
  span.orange-background {
    background: transparent;
    position: relative;
    z-index: 2;
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    display: block;
    &:before {
      content: '';
      width: 140%;
      height: 100%;
      background: transparent;
      position: absolute;
      left: -40%;
      top: 0;
      z-index: -2;
      transition: all 1s ease-in-out;
      transition-delay: 2s;
    }
  }
  &.animation-active {
    span.orange-color {
      color: $cresco-orange;
    }
    span.orange-background,
    .orange-background {
      position: relative;
      background: $cresco-orange;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: $cresco-orange;
      }
    }
  }
  // }
}

@media only screen and (min-width: 900px) {
  .line-custom-animation {
    display: block;
  }
  .section-2 {
    h2 {
      position: relative;
      &:before {
        content: '';
        background: $cresco-orange;
        width: 2000px;
        height: 8px;
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        margin-left: 0;
      }
    }
  }

  .michaelthompson-page,
  .socialjustice-page {
    .cta-slice,
    .fact-slice,
    .partner-slice {
      .text-content {
        .button-container {
          a {
            button {
              width: 30%;
            }
          }
        }
      }
      .partners {
        margin-top: 120px;
        .partner-slide {
          margin-bottom: 80px;
          &:last-of-type {
            margin-bottom: 0;
          }
          &:nth-of-type(even) {
            .partner-logo-link {
              order: 2;
              margin: 0;
              margin-left: 80px;
            }
          }
          display: flex;
          flex-direction: row;
          .partner-logo-link {
            min-width: 200px;
            margin-right: 80px;
            display: block;
            order: 0;
            img {
              width: 100%;
              height: auto;
            }
          }
          .partner-content {
            text-align: left;
            padding: 0px;
            order: 1;
          }
        }
      }
    }
    .special-image-content {
      &.special-image-hero {
        padding: 100px 0;
      }
      .section-padding {
        padding: 100px 60px;
      }
      position: relative;

      .text-content {
        width: 55%;
        z-index: 2;
        position: relative;
        text-align: left;
        &.align-right {
          float: right;
        }
      }
      .image-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .section-5,
    .section-6,
    .section-7 {
      .section-padding {
        padding: 60px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .michaelthompson-page,
  .socialjustice-page {
    .text-background-slice {
      padding-bottom: 80px;
      background-position: center right !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      .text-content {
        text-align: left;
      }
    }
    .special-image-content {
      .text-content {
        h2 {
          &.orange-background {
            &:before {
              width: 100%;
              left: -50%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .michaelthompson-page,
  .socialjustice-page {
    .hero-section {
      height: calc(100vh - 80px);
      .wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-end;
        .text-content {
          margin-top: auto;
          text-align: center;
          height: auto;
        }
      }
    }
    .special-image-content {
      padding-top: 60px;
    }
  }
  .custom-animation {
    &.section-3 {
      span.orange-color {
        color: white;
        transition: all 1s ease-in-out;
      }
      span.orange-background {
        width: 100%;
        display: block;
        padding: 10px 0px;
        &:before {
          width: calc(100% + 40px);
          left: -20px;
        }
      }
      &.animation-active {
        span.orange-color {
          color: $cresco-orange;
        }
      }
    }
  }
}

.video-thumbs-slice {
  .videos-list {
    margin-top: 40px;
    .MuiGrid-item {
      &:last-of-type {
        .video {
          // margin-bottom: 0;
        }
      }
      .video {
        // margin-bottom: 26px;
        img {
          width: 100%;
          height: auto;
          margin-bottom: 27px;
        }
      }
    }
  }
}

.youtube-video-embed {
  .container {
    margin: auto;
    max-width: 768px;
    @media only screen and (min-width: 768px) {
      padding: 0 20px 64px;
    }
    .copy {
      padding: 50px 20px 25px;
    }
    .video-embed-container {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }
  }
}
