@mixin baseButtonStyle {
  width: 100%;
  min-width: 203px;
  min-height: 45px;
  padding-right: 0;
  background: none;
  position: relative;
  padding: 0;
  margin: 0;
  background-size: cover;
  border: none;
  border-radius: 0;

  p {
    font-size: 1.6rem;
    position: relative;
    top: 1px;
  }
}

@mixin baseButtonLargeScreens {
  width: 100%;
  width: 242px;
  height: 52px;
}

@mixin containedLLRButton {
  background: url('../imagesV2/buttonStyles/Cresco_LLR_Primary_Default.svg') no-repeat;
  background-size: cover;
  p {
    color: $darkBlue;
    font-family: Poppins-Semibold;
  }
  &:hover,
  &:focus {
    background: url('../imagesV2/buttonStyles/Cresco_LLR_Primary_Hover.svg') no-repeat;
    background-size: cover;
  }
}

@mixin containedButton {
  background: url('../imagesV2/buttonStyles/Cresco_Primary_Default.svg') no-repeat;
  background-size: cover;
  p {
    color: $darkBlue;
    font-family: Poppins-Semibold;
  }
  &:hover,
  &:focus {
    background: url('../imagesV2/buttonStyles/Cresco_Primary_Hover.svg') no-repeat;
    background-size: cover;
  }
}

@mixin outlinedButton {
  color: $lighterBlue;
  background: url('../imagesV2/buttonStyles/Cresco_Secondary_Default.svg') no-repeat;
  background-size: cover;
  p {
    font-family: Poppins-Semibold;
  }
  &:hover,
  &:focus {
    background: url('../imagesV2/buttonStyles/Cresco_Secondary_Hover.svg') no-repeat;
    background: url('../imagesV2/buttonStyles/Cresco_Secondary_Active.svg') no-repeat;
    background-size: cover;
  }
}
