.react-horizontal-scrolling-menu--scroll-container {
  padding: 0 0 50px 0px;
}

.react-horizontal-scrolling-menu--wrapper,
.react-horizontal-scrolling-menu--inner-wrapper {
  ::-webkit-scrollbar {
    height: 8px;
    border-radius: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $scollbarTrack;
    border-radius: 20px;
    height: 10px;
    margin: 0 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $lighterBlue;
    border-radius: 20px;
  }
}

@media only screen and (min-width: 900px) {
  .react-horizontal-scrolling-menu--wrapper,
  .react-horizontal-scrolling-menu--inner-wrapper {
    /* Track */
    ::-webkit-scrollbar-track {
      margin: 0 100px;
      @media only screen and (min-width: 1500px) {
        margin: 0 400px;
      }
      @media only screen and (min-width: 1800px) {
        margin: 0 600px;
      }
    }
  }
}
