.standard-content-section {
  margin: auto;
  max-width: 800px;
}

.gg-4 {
  display: flex;
  justify-content: center;
  .strain-by-id {
    width: 157px;
    min-height: 157px;
  }
}

.standard-content-section,
.richtext-section {
  h1,
  h2 {
    margin-bottom: 16px;
  }
  .button-container {
    margin-top: 43px;
    gap: 20px;
  }
}

.react-horizontal-scrolling-menu--item {
  display: flex;
  .strain-card {
    display: flex;
    flex-grow: 1;
    .scale {
      display: flex;
      flex-direction: column;
      max-width: 220px;
    }
  }
}

.content-slider-mobile {
  text-align: center;
  .copy {
    max-width: 700px;
    margin: auto;
  }
}

.llr-info {
  text-align: center;
  .llr-wrapper {
    max-width: 1150px;
    margin: auto;
  }
  .copy {
    max-width: 700px;
    margin: auto;
  }
  .info-icons {
    display: flex;
    flex-wrap: wrap;
  }
}

.strains-list {
  display: inline-grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  max-width: 1100px;
  gap: 10px 10px;
  margin-top: 40px;
  @media only screen and (min-width: 360px) {
    grid-template-columns: 157px 157px;
  }
  @media only screen and (min-width: 500px) {
    grid-template-columns: 157px 157px 157px;
  }
  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(auto, 220px));
  }
}

// TERP FINDER

.terpene-finder-slice {
  .content {
    color: white;
    text-align: center;
    h2,
    h3 {
      margin: 0;
      margin-top: 10px;
    }
    .content-wrapper {
      max-width: 800px;
      margin: 0 auto;
    }
  }
  .wrapper {
    .filters {
      text-align: center;
      .filter-item {
        margin: 35px auto;
        .descriptor {
          margin-bottom: 20px;
        }
        .btn-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          gap: 10px 15px;
          .smell-btn,
          .taste-btn {
            background: transparent;
            cursor: pointer;
            border: none;
            color: $lighterBlue;
            position: relative;
            z-index: 3;
            &.disabled {
              opacity: 0.3;
            }

            img {
              width: 50px;
              height: 50px;
              margin: auto;
              display: block;
              position: relative;
              z-index: 3;
            }
            .terpene-list-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              background: none;
              border: none;
            }
          }
        }
      }
    }
    .selected-terpene {
      text-align: center;
      .terpene-icon {
        padding: 50px 30px 40px 30px;
      }
    }
    h6 {
      text-align: center;
    }
    .recommended-strains {
      margin-top: 40px;
      .recommended-strain-copy {
        text-align: center;
        margin-bottom: 30px;
      }
      .strain-placeholder {
        .placeholder-wrapper {
          width: 157px;
          p {
            margin: 0;
          }
          .question {
            background-color: $darkBlue;
            height: 157px;
            width: 157px;
            color: $lighterBlue;
            font-size: 4rem;
          }
          .copy {
            width: inherit;
            height: 43px;
            background-color: $lighterBlue;
            color: $darkBlue;
            font-size: 1.4rem;
          }
        }
      }

      .strain-cards-desktop {
        display: flex;
        flex-direction: row;
        max-width: 900px;
        margin: 0 auto;
        justify-content: center;
        .strain-image {
          width: 100%;
          margin-right: 10px;
          max-width: 400px;
          &:last-of-type {
            margin-right: 0;
          }
          img {
            width: 100%;
            box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .button-container {
        text-align: center;
        margin-bottom: 25px;
      }
    }
  }
}

// TERP FINDER

.double-content-section {
  max-width: 700px;
  margin: auto;
  .first-content,
  .second-content {
    text-align: center;
    h2 {
      margin-bottom: 1.6rem;
    }
  }
}

.terpenes {
  figure {
    width: 50px !important;
    height: 50px !important;
  }
  .name {
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: $lighterBlue;
    margin-top: 8px;
    font-family: 'Poppins-Semibold';
  }
}

.strain-info-section {
  max-width: 900px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 0 50px 50px 50px;
  p {
    margin: 0;
  }
  .strain-info {
    display: grid;
    grid-template-columns: 70px auto;
    gap: 15px;
    .title {
      padding-top: 10px;
    }

    .terpenes-wrapper {
      .terpenes {
        display: flex;
        gap: 20px;
        margin-top: 8px;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.terpenes-feature-slice {
  .content {
    padding: 80px 40px 40px 40px;
    max-width: 850px;
    margin: auto;
  }
  .terpenes-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 160px;
    .terpenes-trypich-section {
      display: inline-grid;
      grid-template-columns: 160px auto;
      gap: 100px;
      width: 600px;
      margin: auto;

      .terpenes-list {
        display: grid;
        grid-template-columns: 75px 75px;
        gap: 30px 20px;

        button {
          background: none;
          border: none;
          color: $lighterBlue;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 2px;
          cursor: pointer;
        }
      }

      .selected-terpene-img-section {
        display: none;
      }

      .selected-terpene-content {
        color: $lightBlue;
        text-align: center;
        padding: 40px;
        border: 1px solid $lightBlue;
        min-height: 740px;

        .terpene-img {
          margin-bottom: 20px;
        }
        .name {
          margin-bottom: 40px;
        }

        h5 {
          font-size: 2.5rem;
          margin-top: 35px;
          margin-bottom: 10px;
        }

        p {
          font-size: 1.6rem;
          margin-bottom: 5px;
        }

        h5:first-of-type {
          margin-top: 20px;
        }

        .terpene-img {
          max-width: 200px;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .strain-by-id .img-wrapper {
    width: auto !important;
  }
}

@media only screen and (min-width: 900px) {
  .gg-4 {
    .strain-by-id {
      width: 220px;
      min-height: 220px;
    }
  }
  .terpenes-feature-slice .terpenes-wrapper .terpenes-trypich-section {
    width: 850px;
  }
  .standard-content-section {
    width: 700px;
  }
  .standard-content-section,
  .richtext-section {
    h1,
    h2 {
      margin-bottom: 32px;
    }
    .button-container {
      margin-top: 80px;
    }
  }

  .strains-list {
    margin-top: 80px;
  }

  .llr-info {
    h1,
    h2 {
      font-size: 6rem;
    }
    .copy {
      margin-bottom: 32px;
    }
    .icons-wrapper {
      margin: auto;
    }
  }

  .strain-info-section {
    .strain-info {
      figure {
        height: 75px;
        width: 75px;
      }
    }
  }

  // TERP FINDER 2

  .terpene-finder-slice {
    .wrapper {
      .filters {
        max-width: 800px;
        margin: 40px auto 100px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        .filter-item {
          margin: 0 auto;
          .btn-wrapper {
            width: 350px;
          }
        }
        &:after {
          content: '';
          border-right: 1px solid $lighterBlue;
          height: 100%;
          position: absolute;
          z-index: 2;
          top: 54%;
          left: 25%;
          transform: translate(-50%, -50%);
          width: 50%;
        }
      }
      .recommended-strains {
        .recommended-strain-copy {
          font-size: 2rem;
        }
        .strain-placeholder {
          max-width: 800px;
          margin: auto;
          .placeholder-wrapper {
            width: 220px;
            margin: auto;
            .question {
              width: 220px;
              height: 220px;

              font-size: 8rem;
            }
          }
        }
      }
    }
  }

  // TERP FINDER 2
}

@media only screen and (min-width: 1200px) {
  .content-slider-mobile {
    text-align: left;
    display: flex;
    justify-content: center;

    .llr-wrapper {
      max-width: 1280px;
      display: flex;
      margin: auto;
      gap: 30px;
    }
    .copy {
      margin: 0;
    }
  }

  .double-content-section {
    .first-content {
      padding-bottom: 40px;
      padding-left: 0;
    }
    .second-content {
      padding-top: 40px;
      padding-left: 0;
    }
    .first-content,
    .second-content {
      text-align: left;
      h2 {
        margin-bottom: 3.2rem;
        font-size: 6rem;
      }
    }
  }

  .content-dram-section {
    padding: 100px 0;
    // display: flex;
    display: grid;
    grid-template-columns: 400px auto;
    gap: 60px;
    max-width: 1500px;
    margin: auto;
  }

  .strain-info-section {
    padding-top: 80px;
    max-width: 1200px;
    // min-width: 1200px;
    gap: 50px;
    .strain-info {
      grid-template-columns: 75px auto;
      .title {
        font-size: 1.4rem;
        padding-top: 0;
      }
      .content {
        font-size: 3.6rem;
      }
    }
  }

  .terpene-finder-slice {
    .wrapper {
      .filters {
        max-width: 1200px;
        margin-top: 80px;
        .filter-item {
          .btn-wrapper {
            width: 500px;
            gap: 30px;
            .smell-btn,
            .taste-btn {
              img {
                width: 75px;
                height: 75px;
              }
            }
          }
        }
      }
      .strain-placeholder {
        max-width: 800px;
        margin: auto;
      }
    }
  }
}

@media only screen and (min-width: 1300px) {
  .terpenes-feature-slice {
    .content {
      padding: 140px 40px 60px;
    }
    .terpenes-wrapper {
      .terpenes-trypich-section {
        grid-template-columns: auto 500px 347px;
        @media only screen and (min-width: 1450px) {
          grid-template-columns: auto 500px 400px;
        }
        gap: 75px;
        position: relative;
        left: 60px;
        width: 1100px;

        .terpenes-list {
          gap: 30px;
        }

        .selected-terpene-img-section {
          display: block;
          padding-top: 80px;
          border: 1px solid $lighterBlue;
          figure {
            width: 350px;
            margin: auto;
          }
          .name {
            padding-top: 60px;
          }
        }

        .selected-terpene-content {
          border: none;
          text-align: left;
          padding: 0;
          margin-left: -30px;
          min-height: 620px;
          padding-right: 50px;
          .terpene-img,
          .name {
            display: none;
          }
          h5 {
            font-size: 3rem;
          }
          h5:first-of-type {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.floating-faq {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
  button {
    border-radius: 0;
    background: $darkBlue;
    border: 1px solid $lightBlue;
    color: $lightBlue;
    padding: 5px;
    span {
      text-transform: none;
    }
  }
}

.faq-module-wrapper {
  padding: 20px;
  background: $lighterBlue;
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  overflow: scroll;
  h2 {
    font-size: 20px;
    color: $darkBlue;
    text-transform: none;
    letter-spacing: 0px;
  }
  p {
    font-size: 14px;
    color: $darkBlue;
  }
  .MuiButton-root {
    border-radius: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: $darkBlue !important;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $darkBlue !important;
  }
  .PrivateNotchedOutline-legendLabelled-9 > span,
  .MuiInputLabel-outlined {
    color: $darkBlue !important;
  }
  .MuiInputLabel-outlined {
    top: -1px;
  }
  .MuiOutlinedInput-root {
    width: 150%;
    color: $darkBlue;
    @media only screen and (min-width: 390px) {
      width: 170%;
    }
    @media only screen and (min-width: 800px) {
      width: 200%;
    }
  }
  .MuiTableCell-root {
    padding: 10px 0px;
    border: 0;
    .MuiBox-root {
      margin: 0;
      h2 {
        margin: 0;
        cursor: pointer;
      }
      &.answer {
        padding: 5px 0px;
      }
    }
  }
  .MuiSvgIcon-root {
    color: $darkBlue;
    fill: $darkBlue;
  }
}

@media only screen and (min-width: 1400px) {
  .strains-list {
    justify-content: center;
    align-content: center;
    margin-top: 80px;
  }
  .content-slider-mobile {
    .llr-wrapper {
      gap: 100px;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .content-slider-mobile {
    .llr-wrapper {
      gap: 50px;
    }
  }

  .strain-info-section {
    .strain-info {
      .title {
        font-size: 2.6rem;
      }
      .content {
        font-size: 4rem;
      }
    }
  }
}
