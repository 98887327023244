.hero-overlay:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: url('../imagesV2/backgrounds&Textures/overlay-mobile.png') no-repeat;
  background-size: cover;

  @media only screen and (min-width: 900px) {
    background: url('../imagesV2/backgrounds&Textures/overlay-desktop.png') no-repeat;
  }
}

.border,
.border span,
.lazy-img-border span {
  border: 1px solid $lighterBlue;
}

.border-bottom {
  border-bottom: 1px solid $lighterBlue;
}

.llr-info {
  .llr-gold-stream-content {
    position: absolute;
    top: -51px;
    left: 0;
    height: auto;
    width: auto;
    z-index: 2;
    @media only screen and (min-width: 400px) {
      top: -59px;
    }
    @media only screen and (min-width: 580px) {
      top: -81px;
    }
    @media only screen and (min-width: 680px) {
      top: -100px;
    }
    @media only screen and (min-width: 847px) {
      top: -120px;
    }
    @media only screen and (min-width: 1016px) {
      top: -150px;
    }
    @media only screen and (min-width: 1235px) {
      top: -170px;
    }
    @media only screen and (min-width: 1425px) {
      top: -210px;
    }
    @media only screen and (min-width: 1758px) {
      top: -250px;
    }
    @media only screen and (min-width: 2046px) {
      top: -300px;
    }
  }
}

.second-content {
  padding: 80px 35px;
  @media only screen and (min-width: 590px) {
    padding-bottom: 120px;
  }
  @media only screen and (min-width: 980px) {
    padding-bottom: 170px;
  }
  @media only screen and (min-width: 1235px) {
    padding-bottom: 200px;
  }
  @media only screen and (min-width: 1441px) {
    padding-bottom: 240px;
  }
  @media only screen and (min-width: 1848px) {
    padding-bottom: 330px;
  }
}

.llr-content-padding {
  padding: 80px 35px;
  @media only screen and (min-width: 520px) {
    padding-top: 140px;
  }
  @media only screen and (min-width: 834px) {
    padding-top: 180px;
  }
  @media only screen and (min-width: 982px) {
    padding-top: 220px;
  }
  @media only screen and (min-width: 1235px) {
    padding-top: 260px;
  }
  @media only screen and (min-width: 1441px) {
    padding-top: 300px;
  }
  @media only screen and (min-width: 1441px) {
    padding-top: 350px;
  }
  @media only screen and (min-width: 2108px) {
    padding-top: 450px;
  }
}

.llr-hero-padding {
  padding: 80px 35px 40px;
  @media only screen and (min-width: 500px) {
    padding: 120px 35px 40px;
  }
  @media only screen and (min-width: 900px) {
    padding: 160px 35px 40px;
  }
  @media only screen and (min-width: 1130px) {
    padding: 250px 50px 40px;
  }
  @media only screen and (min-width: 1651px) {
    padding: 350px 50px 40px;
  }
}

.icons-wrapper {
  @media only screen and (min-width: 1200px) {
    padding-top: 250px;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media only screen and (min-width: 1651px) {
    padding-top: 350px;
  }
}

.llr-hero {
  @media only screen and (min-width: 1392px) {
    min-height: 1100px !important;
  }
  .llr-gold-stream-hero {
    position: absolute;
    top: 657px;
    right: 0;
    z-index: 3;
    width: auto;
    height: auto;
    @media only screen and (min-width: 450px) {
      top: 643px;
    }
    @media only screen and (min-width: 582px) {
      top: 626px;
    }
    @media only screen and (min-width: 700px) {
      top: 626px;
    }
    @media only screen and (min-width: 800px) {
      top: 612px;
    }
    @media only screen and (min-width: 900px) {
      top: 791px;
    }
    @media only screen and (min-width: 1150px) {
      top: 778px;
    }
    @media only screen and (min-width: 1278px) {
      top: 765px;
    }
    @media only screen and (min-width: 1392px) {
      top: 939px;
    }
    @media only screen and (min-width: 1651px) {
      top: 939px;
    }
    @media only screen and (min-width: 1683px) {
      top: 903px;
    }
    @media only screen and (min-width: 2034px) {
      top: 869px;
    }
  }
}
