.carousel .control-dots .dot {
  background: $lightBlue;
}

.info-carousel-padding {
  padding-bottom: 50px;
}

.info-carousel {
  position: relative;
  .carousel-slider {
    margin: 0 auto;
    overflow: visible;
    position: relative;
    .control-arrow {
      background: url('../imagesV2/buttonStyles/right-arrow.svg') no-repeat !important;
      opacity: 1 !important;
      height: 25px;
      &.control-next {
        top: 133px;
        left: 200px;
      }
      &.control-prev {
        top: 133px;
        left: -85px;
      }
    }
  }
  .slider-wrapper {
    overflow: visible;
  }

  .slide {
    pointer-events: none;
    .active-icon {
      display: none;
    }
    .inactive-icon {
      display: block;
    }
    .terpenes {
      display: none;
    }
    .attributes-wrapper {
      display: none;
    }
    &.selected {
      .active-icon {
        display: block;
      }
      .inactive-icon {
        display: none;
      }
      .terpenes {
        margin-top: 15px;
        position: relative;
        margin-left: -66px;
        figure {
          width: 50px;
          height: 50px;
        }
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 25px;
        @media only screen and (max-width: 372px) {
          position: static;
          margin: 15px 0 0 0;
          grid-template-columns: 1fr 1fr;
        }
      }
      .attributes-wrapper {
        display: block;
        margin-top: 100px;
      }
    }
  }

  .control-dots {
    display: inline-flex;
    width: 200%;
    justify-content: center;
    position: absolute;
    top: 134px;
    width: auto;
    white-space: nowrap;
    transform: translate(-50%, -6%);
    left: 50%;
    height: 15px;
  }
  &.terpene-info .control-dots {
    top: 134px;
    height: 15px;
    width: auto;
    .dot {
      margin: 0 4px;
    }
  }
}

.info-slide {
  margin-bottom: 50px;

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 75px;
    height: 75px;
    .active-icon,
    .inactive-icon {
      height: inherit;
      width: inherit;
    }
    &.carousel-icon-style {
      height: 100px;
      width: 100px;
      .active-icon {
        height: 100px;
        width: 100px;
      }
      .inactive-icon {
        height: 50px;
        width: 50px;
      }
    }
  }

  .content {
    font-size: 2.8rem;
  }
}

@media only screen and (min-width: 500px) {
  .info-slide {
    .carousel-icon-style {
      height: 150px;
      width: 150px;

      .active-icon {
        height: 150px;
        width: 150px;
      }
      .inactive-icon {
        height: 70px;
        width: 70px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .info-slide {
    .carousel-icon-style {
      height: 170px;
      width: 170px;

      .active-icon {
        height: 170px;
        width: 170px;
      }
      .inactive-icon {
        height: 100px;
        width: 100px;
      }
    }
  }

  .info-carousel {
    .control-dots {
      top: 220px;
    }
  }
}
