.dark-flower {
  position: relative;
  background: $darkBlue;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url('../imagesV2/backgrounds&Textures/flower-background.jpg') no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  &.background-repeat {
    &:after {
      background: url('../imagesV2/backgrounds&Textures/flower-background.jpg') repeat;
    }
  }
}

.dark-blue-splatter {
  background-color: $darkBlue;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url('../imagesV2/backgrounds&Textures/blue-dots-background-mobile.png') no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: 0 -100px;
  }
}

.background-wrapper {
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 900px) {
  .dark-blue-splatter {
    &:after {
      background: url('../imagesV2/backgrounds&Textures/blue-dots-background-desktop.png') no-repeat;
      background-size: cover;
      background-position: 0% 0%;
    }
  }
}
