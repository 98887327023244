@mixin blueGradient {
  background: $lighterBlue;
  background: linear-gradient(
    120deg,
    $lighterBlue,
    $lighterBlue,
    #f8fcfe,
    $lighterBlue,
    $lighterBlue,
    $lighterBlue,
    $lighterBlue
  );
  background-size: 400% 400%;
  background-position: 100% 0;
  transition: background-position 0.8s ease-in-out;
}

.scale {
  overflow: hidden;
  img {
    width: 100%;
    transition: all 800ms ease-in-out;
  }
  .blue-gradient {
    @include blueGradient;
  }
  &:hover img {
    transform: scale(1.1);
  }
  &:hover .blue-gradient {
    background-position: 0 0;
  }
}

.scale-text {
  .MuiButton-label p,
  p {
    transition: 200ms linear;
    transform: scale(1);
  }
  &:hover .MuiButton-label p,
  &:hover p {
    transform: scale(1.1);
  }
}
.menu-icon {
  -webkit-tap-highlight-color: transparent;
  svg {
    width: 45px;
    .animate-line {
      transition: 200ms linear all;
      transform: translate(12px, -4px) scaleX(0.55);
    }
  }
  &.animate-menu {
    svg {
      .animate-line {
        transform: translate(0, 0) scaleX(1);
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 170px;
  height: 170px;
  background: transparent;
  border: 3px solid #304758;
  border-radius: 50%;
  text-align: center;
  line-height: 170px;
  font-family: sans-serif;
  font-size: 2rem;
  color: $lightBlue;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px $lightBlue;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  &:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 170px;
    height: 170px;
    border: 3px solid transparent;
    border-top: 3px solid $lightBlue;
    border-right: 3px solid $lightBlue;
    border-radius: 50%;
    animation: animateC 2s linear infinite;
  }

  span {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: animate 2s linear infinite;
    &:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $lightBlue;
      top: -6px;
      right: -8px;
      box-shadow: 0 0 20px $lightBlue;
    }
  }

  &.small {
    width: 70px;
    height: 70px;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: bold;
    &:before {
      width: 70px;
      height: 70px;
    }
    .text {
      position: relative;
      bottom: 50px;
    }

    span {
      &:before {
        width: 8px;
        height: 8px;
        top: 0px;
        right: -6px;
      }
    }
  }
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
