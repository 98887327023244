@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
}

.oswaldBold {
  font-family: 'oswald', sans-serif;
  font-weight: 700;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  margin: 0;
}

h1 {
  font-size: 4rem;
  font-family: 'oswald', sans-serif;
  font-style: normal;
  line-height: 1.2;
  color: $lightBlue;
  .light-blue-color {
    color: $lighterBlue;
  }
  .gold {
    color: $gold;
  }
}

h2,
.llr-headline h1,
h2 {
  font-size: 2.8rem;
  font-family: 'oswald', sans-serif;
  font-style: normal;
  line-height: 1.2;
  color: $lightBlue;
  .light-blue-color {
    color: $lighterBlue;
  }
  .gold {
    color: $gold;
  }
}

h3 {
  font-family: 'Poppins-Semibold';
  font-size: 2rem;
}

h5 {
  font-family: 'Poppins-Semibold';
}

p {
  font-size: 1.3rem;
  font-family: 'Poppins-Regular';
  color: $lighterBlue;
  line-height: 1.4;
  a {
    color: inherit;
  }
}

h4,
h6 {
  color: $lightBlue;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: 'Poppins-Semibold';
}

footer h2 {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: 'Poppins-Bold';
  text-transform: uppercase;
  margin-bottom: 1.2rem;
}

.poppins-container {
  p {
    font-family: 'Poppins-Semibold';
  }
}

.poppins-semibold {
  font-family: 'Poppins-Semibold';
  text-transform: uppercase;
}

.question h2 {
  font-size: 1.4rem;
  font-family: 'Poppins-Semibold';
  text-transform: capitalize;
  @media only screen and (min-width: 900px) {
    font-size: 2.6rem;
  }
}

.strain-info-slider {
  color: $lighterBlue;

  h5:first-child {
    margin: 0 auto 15px;
  }
  h5 {
    font-size: 2.2rem;
    margin: 50px auto 15px;
    line-height: 1.2;
  }
  p {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .title {
    font-size: 1.4rem;
  }
}

.answer p {
  font-size: 1.2rem;
  @media only screen and (min-width: 900px) {
    font-size: 2rem;
  }
}

@media screen and (min-width: 900px) {
  h1 {
    font-size: 8rem;
    line-height: 1;
  }
  h2 {
    font-size: 5.5rem;
  }
  h4,
  h6 {
    font-size: 2.6rem;
  }

  footer h2,
  footer a,
  footer p {
    font-size: 1.4rem;
  }
  p {
    font-size: 1.6rem;
  }

  .llr-headline h1,
  .llr-headline h2 {
    font-size: 6rem;
  }
}

@media screen and (min-width: 1400px) {
  h1 {
    font-size: 12rem;
  }
}
