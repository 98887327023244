.map-wrapper {
  margin-top: 10px;

  ::-webkit-scrollbar {
    height: 5px;
    border-radius: 20px;
    scrollbar-width: thin;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $scollbarTrack;
    border-radius: 20px;
    height: 5px;
    margin: 0 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $lighterBlue;
    border-radius: 20px;
  }

  .interactive-map {
    height: 400px !important;
    width: 100% !important;
    position: relative !important;
    display: block;
  }

  .info-window-content {
    .store-name {
      font-size: 1.4rem;
      color: $darkBlue;
      font-family: 'Poppins-Semibold';
      margin-bottom: 5px;
    }
    .store-address {
      margin-bottom: 10px;
    }
    .store-address,
    .usage-type {
      color: $darkBlue;
      font-size: 1.2rem;
    }
  }
  .locations-list-wrapper {
    display: block;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .locations-list-title {
      margin: 0 0 20px 0;
      padding: 0px 20px;
      color: $lightBlue;
    }
    .pagination-nav {
      padding: 8px 20px;
      display: flex;
      justify-content: space-between;
      background: $blackSeeThrough;
      font-family: 'Poppins-Semibold';
      color: $lighterBlue;
      text-transform: uppercase;
      .page-controls {
        span {
          cursor: pointer;
          &:nth-of-type(1) {
            margin-right: 10px;
          }
        }
      }
    }
    .info-message {
      background: $blackSeeThrough;
      color: $lighterBlue;
      padding: 8px 20px;
      font-family: 'Poppins-Semibold';
      text-transform: uppercase;
      display: flex;
      border-bottom: 1px solid $lightBlue;
    }
    .locations-search-form {
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-bottom: 40px;
    }
    .locations-list {
      background: transparent;
      overflow-y: hidden;
      overflow-x: hidden;
      height: 100%;
      position: relative;
      margin-top: 10px;

      .single-location {
        padding: 20px;
        overflow: auto;
        border-bottom: 1px solid $lightBlue;
        display: flex;
        flex-direction: row;
        gap: 20px;
        &:last-of-type {
          border-bottom: 0;
        }
        &.active {
          .store-information {
            outline: 1px solid $lightBlue;
            padding: 10px;
          }
        }

        .store-information {
          margin-right: 25px;
          width: 100%;
          color: $lighterBlue;
          text-align: left;
          .store-name {
            margin-bottom: 5px;
            color: $lighterBlue;
            text-transform: capitalize;
          }
          .store-address {
            font-size: 1.2rem;
            line-height: 1.2;
            margin-bottom: 10px;
          }
          .distance {
            font-size: 1.2rem;
            line-height: 1.2;
            margin: 20px 0 10px;
            color: $lightBlue;
          }
          .usage-type {
            font-size: 1.2rem;
            font-weight: 900;
            margin-bottom: 10px;
          }
        }
        .store-actions {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;

          .icon-button {
            font-family: 'Poppins-Semibold';
            color: $lightBlue;
            font-size: 10px;
            text-decoration: none;
            font-weight: 900;
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            text-transform: uppercase;
            img {
              width: 20px;
              height: 20px;
              display: block;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .map-wrapper {
    overflow: auto;

    .back-to-top {
      display: none;
    }
    .section-container {
      display: flex;
      justify-content: center;
      max-width: 1300px;
      margin: auto;
    }

    .info-window-content {
      text-align: left;
      .store-name {
        font-size: 1.6rem;
      }
      .store-address {
        font-size: 1.4rem;
      }
    }

    .store-information {
      .store-name {
        font-size: 1.6rem;
      }
      .store-address {
        margin-top: 10px;
      }
      .store-address,
      .distance {
        font-size: 1.4rem !important;
      }
    }

    .map-list-wrapper {
      text-align: center;
      display: flex;
      width: 1300px;
    }
    .map-wrapper {
      height: calc(100vh - 100px) !important;
      width: 60%;
      order: 2;
      margin-top: 0;
      margin-left: 30px;

      .interactive-map {
        height: calc(100vh - 100px) !important;
        width: 100% !important;
      }
    }
    .stores-wrapper {
      height: calc(100vh - 100px) !important;
    }
    .locations-list-wrapper {
      .locations-list {
        height: calc(100vh - 180px) !important;
        overflow-y: scroll;
        width: 500px;
      }
      .locations-list-title {
        margin: 0 0 30px 0;
      }
      .info-message,
      .pagination-nav {
        font-size: 1.2rem;
      }
      .pagination-nav {
        margin-bottom: 15px;
      }
    }
  }
}
