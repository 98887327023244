nav {
  min-height: 56px;
  position: fixed;
  width: 100%;
  margin: auto;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  .mobile-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 0 0 17px;
    background: $blackish;
  }

  // new styles here
  .flower-icon {
    width: 45px;
    height: 45px;
    margin: 0;
    padding: 0;
    margin-top: -5px;
  }
  ul {
    display: none;
    list-style: none;
    padding: 38px 0;
    li {
      height: 42px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      transition: 300ms ease-in;
      &:hover,
      &:active,
      &.active {
        background-color: $mobileHover;
      }
    }
    a {
      text-transform: uppercase;
      display: block;
      font-size: 1.4rem;
      font-family: Poppins-Semibold;
      color: $lightBlue;
      text-decoration: none;
    }

    &.openNav {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $blackSeeThroughLess;
      color: $lightBlue;
      margin: 0;
    }
  }

  #nav-logo img {
    width: 140px;
  }
}
.underline {
  &:hover {
    text-decoration: underline;
  }
  #simple-menu {
    ul {
      background: $blackSeeThroughLess;

      a {
        text-decoration: none !important;
        color: $lightBlue !important;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (min-width: 905px) {
  nav {
    top: 0;
    width: 100%;
    border-radius: 0;
    border: none;
    height: 60px;
    background-color: $blackSeeThrough;

    .nav-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 1800px;
      margin: auto;
    }

    ul,
    ul.openNav {
      display: flex;
      flex-direction: row;
      background-color: transparent;
      justify-content: center;
      align-items: center;
      padding: 0px;
      margin: 0;

      li {
        margin-right: 25px;
        &:hover,
        &:active,
        &.active {
          background: none;
          a {
            text-decoration: underline;
          }
        }
        a {
          font-size: 1.2rem;
          white-space: nowrap;

          @media only screen and (min-width: 1050px) {
            font-size: 1.4rem;
          }
        }
      }

      li:nth-last-child(2) {
        margin-right: 15px;
      }
    }
  }
}

@media only screen and (min-width: 1270px) {
  nav {
    #nav-logo img {
      width: 260px;
    }
  }
}
