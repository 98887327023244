#age-gate {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  padding: 40px;
  box-sizing: border-box;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .age-gate-content {
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    z-index: 3;
    max-height: 600px;
    align-self: center;
    text-align: center;
    position: relative;
    a {
      color: inherit;
    }
    .spanish-toggle {
      position: absolute;
      top: 0;
      right: 0;
      color: $lighterBlue;
      margin-top: 10px;
      z-index: 3;
      .switch-text {
        font-size: 1.4rem;
        display: inline-block;
        position: relative;
        right: 15px;
        top: 2px;
        margin-left: 5px;
        font-family: sans-serif;
      }
    }
    .logo {
      width: 100%;
      height: auto;
      max-width: 400px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .content-section {
      color: $lighterBlue;
    }
    h2 {
      padding-top: 40px;
      width: 100%;
      max-width: 400px;
      margin: 20px auto 25px;
      line-height: 1.1;
    }
    p {
      margin: 20px 0px;
    }
    form {
      margin-bottom: 20px;
      line-height: 1;
      text-align: center !important;
      display: block;
      span {
        font-size: 1.4rem;
        color: $lighterBlue;
      }

      .error-message {
        display: inline-block;
        width: auto;
        padding: 10px 15px;
        background: $error;
        color: white;
        box-sizing: border-box;
        font-size: 1.4rem;
        margin-top: 0;
      }
    }
    .legal-text {
      p {
        font-size: 15px;
      }
      a {
        font-weight: 900;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #age-gate {
    .age-gate-content {
      padding: 50px;
    }
  }
}

.faq-popover {
  .MuiPopover-paper {
    bottom: 20px;
    right: 20px;
  }
}
.shop-page-dialog {
  .MuiPaper-rounded {
    border-radius: 0px !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 1034px !important;
  }
}

.dialog-wrapper {
  position: relative;
  background: $darkBlue;
  text-align: center;

  .content-img-wrapper {
    padding: 17px 35px;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    .sunny-logo,
    .jane-logo {
      margin: 0 auto 25px;
      display: block;
    }
    .sunny-logo {
      width: 250px;
    }
    .jane-logo {
      width: 150px;
    }
    .shop-sunny {
      padding-bottom: 35px;
      margin-bottom: 32px;
    }

    a {
      button {
        font-size: 12px;
        border-radius: 20px;
        height: 30px;
      }
    }
  }
  .logo-container {
    margin-bottom: 20px;
    svg {
      max-width: 150px;
    }
  }
  .content {
    margin: 25px auto 10px;
    max-width: 450px;
    p {
      font-size: 14px;
    }
  }
  .disclaimer-text {
    p {
      font-size: 12px;
      line-height: 14px;
      max-width: 500px;
      margin: 35px auto 0 auto;
    }
  }
}

@media only screen and (min-width: 900px) {
  .dialog-wrapper {
    .content-img-wrapper {
      padding: 30px 35px;
    }
    .button-container {
      flex-direction: row;
      margin-top: 30px;
      gap: 30px !important;
      .sunny-logo,
      .jane-logo {
        display: block;
      }
      .sunny-logo {
        width: 400px;
        margin-bottom: 35px;
      }
      .jane-logo {
        width: 247px;
      }
      .shop-sunny {
        padding-bottom: 0;
        margin-bottom: 0;
        padding: 15px 35px 0 35px;
        position: relative;
        top: 34px;
      }
      .shop-jane {
        padding: 15px 35px 0 35px;
      }

      a {
        button {
          font-size: 16px;
          border-radius: 20px;
          height: 35px;
        }
      }
    }
    .logo-container {
      margin-bottom: 20px;
      svg {
        max-width: 150px;
      }
    }
    .content {
      margin-bottom: 50px;
      p {
        font-size: 18px;
      }
    }
    .disclaimer-text {
      margin-top: 50px;
    }
  }

  // AGE GATE
  // #age-gate {
  //   .age-gate-content {
  //     padding: 50px;
  //   }
  // }
  // AGE GATE
}
