$darkBlue: #001f3b;
$blackish: #02060a;
$lightBlue: #87c7f7;
$lighterBlue: #b9e0f7;
$mobileHover: rgba(101, 157, 200, 0.5);
$gold: #d9a700;
$scollbarTrack: rgba(185, 224, 247, 0.5);
$blackSeeThrough: rgba(2, 6, 10, 0.7);
$blackSeeThroughLess: rgba(2, 6, 10, 0.9);
$cresco-orange: #f4c01a;
$cresco-orange-yellow: #f5b434;
$error: #c41e3a;
