footer {
  padding: 24px 35px;

  a {
    display: inline-block;
    text-decoration: none;
    font-family: 'Poppins-Regular';
    &:hover {
      text-decoration: underline;
    }
  }

  .social-link,
  .social-link img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  .footer-section {
    p {
      margin: 0;
    }
    a {
      margin-bottom: 10px;
      color: $lightBlue;
    }
  }

  .link-grid {
    max-width: 450px;
    display: grid;
    grid-template-areas:
      'a b'
      'a c'
      'a .'
      'a .';
    gap: 30px 25px;

    .footer-section:nth-child(1) {
      grid-area: a;
    }
    .footer-section:nth-child(2) {
      grid-area: b;
    }
    .footer-section:nth-child(3) {
      grid-area: c;
    }
  }
}

@media only screen and (min-width: 800px) {
  footer {
    padding: 64px;

    .link-grid {
      max-width: 400px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  footer {
    margin-left: 20%;
  }
}
